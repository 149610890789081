<ng-container *ngIf="activeUser$ | async as activeUser">
  <div class="officer-information-wrapper">
    <section class="information-container" *ngIf="item?.officer?.officerProfile as officerProfile">
      <div class="!mb-2 w-full flex flex-col text-sub">
        <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Type</p>
        <p
          class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
          {{item.officer.isIndividual ? 'Individual' : item.officer.isCorporate ? 'Corporate' : ''}}
        </p>
      </div>

      <!--      Officer Individual Type -->
      <ng-container *ngIf="officerProfile.__typename === 'OfficerPersonType'">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Position
                                                                                                     Held&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.renderOfficerRole}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Name&colon;</p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.officer.name}}</p>
          </div>
          <ng-container *ngIf="!item.isNominee">
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Identification Document&colon;</p>
              <p
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.identification?.officerIdentificationType?.label}}</p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Identification No&colon;</p>
              <p
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.identification?.identificationNumber}}</p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Expiry Date&colon;</p>
              <p
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{!officerProfile.identification?.expiryDate ? '-' : (officerProfile.identification?.expiryDate |date)}}</p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Nationality&colon;</p>
              <span
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.identification?.nationality?.name}}</span>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Email&colon;</p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.email}}</p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Phone&colon;</p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.address.phone}}</p>
            </div>
            <div class="col-span-2 !mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Address&colon;</p>
              <p
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray w-[75%]">{{officerProfile.address.fullAddress}}</p>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!--      Officer Corporate Type -->
      <ng-container *ngIf="officerProfile.__typename === 'OfficerCompanyType'">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Position
                                                                                                     Held&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.renderOfficerRole}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Corporate
                                                                                                     Name&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{officerProfile.name}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Country of
                                                                                                     Incorporation&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.country.name}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Registration
                                                                                                     Number&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.registrationNumber}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Email&colon;</p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.email}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Phone&colon;</p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.address.phone}}</p>
          </div>
          <div class="col-span-2 !mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Address&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 text-darkGray w-[75%]">{{officerProfile.address.fullAddress}}</p>
          </div>
          <ng-container *ngIf="item.officer.isCorporate">
            <ng-container *ngIf="getRepresentativeInfo(officerProfile) as representativeProfile">
              <mat-divider class="w-1/2 !mx-auto !my-3 !col-span-2"></mat-divider>
              <p class="col-span-2 font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Corporate Representative:
              </p>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Name&colon;</p>
                <p
                  class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
                  {{representativeProfile.firstName + ' ' + representativeProfile.lastName}}</p>
              </div>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                  Identification Document&colon;</p>
                <p
                  class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                  {{representativeProfile.identification.officerIdentificationType.name ?? '-'}}
                </p>
              </div>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                  Identification No&colon;</p>
                <p
                  class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                  {{representativeProfile.identification.identificationNumber}}
                </p>
              </div>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Expiry Date&colon;</p>
                <p
                  class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                  {{(representativeProfile?.identification?.expiryDate | date) ?? '-'}}

                </p>
              </div>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Nationality&colon;</p>
                <span
                  class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                  {{representativeProfile?.identification?.nationality?.name ?? '-'}}</span>
              </div>
              <div class="!mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                  Email&colon;</p>
                <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                  {{representativeProfile.email}}
                </p>
              </div>
              <div class="col-span-2 !mb-1 w-full flex flex-col">
                <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                  Address&colon;</p>
                <p
                  class="mat-subheading-1 !text-sub !mb-0 text-darkGray w-[75%]">
                  {{commonHelper.renderAddress(representativeProfile.address)}}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="officerProfile.__typename === 'OfficerIndirectPersonType'">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Position Held&colon;</p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.renderOfficerRole}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Name&colon;</p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.officer.name}}</p>
          </div>
          <ng-container>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Nationality&colon;</p>
              <span
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.nationality?.name}}</span>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Residence&colon;</p>
              <span
                class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.country?.name}}</span>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="officerProfile.__typename === 'OfficerIndirectCompanyType'">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Position Held&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{item.renderOfficerRole}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Corporate
                                                                                                     Name&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">{{officerProfile.name}}</p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">Country of
                                                                                                     Incorporation&colon;</p>
            <p
              class="mat-subheading-1 !text-sub !mb-0 text-darkGray">{{officerProfile.country.name}}</p>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>

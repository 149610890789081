<div class="main-request-wrapper">
  <mat-card class="!px-8 !pt-8">
    <mat-card-content class="text-darkGray">
      <!--            MAIN INPUT-->
      <section class="main-request-container mb-6">
        <p class="!mb-2 mat-title text-darkGray !text-base">
          {{ job.getMatterDisplay() }} Information
        </p>
        <ng-template dynamicComponent></ng-template>
      </section>

      <!--            ADDITIONAL REQUEST/INFORMATION-->
      <section class="additional-request-container"
               *ngIf="additionalRequest.length && job.service.name !== ServiceEnum.IncreaseCapital">
        <mat-divider class="!mx-auto w-1/2 !my-8"></mat-divider>
        <p class="!mb-2 mat-title text-darkGray !text-base">
          Additional Request
        </p>
        <ul class="!text-sub pl-8 py-4 border border-solid rounded-2xl border-[#0000001f]">
          <li *ngFor="let item of additionalRequest">
            <p class="mat-subheading-1 !text-sub !mb-0">
              {{ item.service.label }}
            </p>
            <p class="mat-caption text-lightGray !mb-1">
              Quantity&colon;&nbsp;{{ item.quantity }}
            </p>
          </li>
        </ul>
      </section>
    </mat-card-content>
  </mat-card>
</div>

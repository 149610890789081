import { ModelFactory } from '@shared/factories/model.factory';
import { UserType } from '@generated/graphql';
import { User } from '@modules/user/models/user.model';
import { faker } from '@faker-js/faker';
import { profileFactory } from '@modules/profile/factories/profile.factory';
import { roleFactory } from '@modules/role/factories/role.factory';

export class UserFactory extends ModelFactory<UserType, User> {
  create (state: UserType): User {
    return new User(state);
  }

  fake (): User {
    return this.create({
      id: faker.datatype.uuid(),
      email: faker.internet.email(),
      profile: profileFactory.fake(),
      canUpdate: false,
      canReactivate: false,
      canDeactivate: false,
      canImpersonate: false,
      isActive: true,
      roles: faker.helpers.arrayElements([roleFactory.fake(), roleFactory.fake(), roleFactory.fake()]),
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
      billingAddresses: [],
    });
  }
}

export const userFactory = new UserFactory();

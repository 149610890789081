<div class="extra-jobs-wrapper"
     *ngIf="activeUser$ | async as activeUser">
  <mat-card class="!px-8 !pt-8"
            *ngrxLet="filteredJobs$ as filteredJobs">
    <mat-card-content>
      <section class="flex flex-row justify-between items-center mb-4">
        <div>
          <mat-form-field appearance="outline"
                          class="w-72">
            <mat-label>Search jobs</mat-label>
            <mat-icon matPrefix
                      class="text-darkGray opacity-70">search
            </mat-icon>
            <input [formControl]="searchControl"
                   matInput
                   placeholder="Search service">
            <button
              (click)="searchControl.reset('')"
              matSuffix
              mat-icon-button>
              <mat-icon class="text-darkGray opacity-70">backspace</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button
          *ngIf="canCreateExtraJob$()|async"
          (click)="openDialog()"
          mat-raised-button
          color="primary">
          <mat-icon>add</mat-icon>
          Create Extra Job
        </button>
      </section>
      <p *ngIf="searchControl.value">
        Found <span class="font-bold">{{ filteredJobs.length }}</span> record(s)
      </p>
      <section class="table-container table-container-limited-scroll mat-elevation-z4">
        <table mat-table
               class="table-custom"
               [dataSource]="filteredJobs">
          <!--          Job Matter-->
          <ng-container matColumnDef="matter">
            <th mat-header-cell
                *matHeaderCellDef
                class="!text-white w-52">Job Matter
            </th>
            <td mat-cell
                *matCellDef="let element">
              <p class="!mb-0 line-clamp-3">{{ element.matter }}</p>
            </td>
          </ng-container>

          <!--  Job Status-->
          <ng-container matColumnDef="status">
            <th mat-header-cell
                *matHeaderCellDef
                class="!text-white !text-center">Job Status
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="text-center">
              <app-status
                [ngClass]="'text-sm'"
                [statusMessage]="element.renderStatusLabel()"
                [statusStyle]="element.renderStatusStyle()">
              </app-status>
            </td>
          </ng-container>

          <!--  Payment -->
          <ng-container matColumnDef="order">
            <th mat-header-cell
                *matHeaderCellDef
                class="!text-white !text-center">Payment
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="text-center">
              <app-status
                [statusMessage]="element.order.payment ? element.order.payment.renderStatusLabel() : element.order.paymentStatusAndStyleIfUserDidNotMakePayment.status"
                [statusStyle]="element.order.payment ? element.order.payment.renderStatusStyle(): element.order.paymentStatusAndStyleIfUserDidNotMakePayment.statusStyle"
                [ngClass]="'text-sm'"></app-status>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell
                *matHeaderCellDef
                class="!text-white !text-center">Total Amount
            </th>
            <td mat-cell
                *matCellDef="let element">
              <p class="!mb-0 text-center">
                {{ element.totalAmount | currency:element.currency:'symbol':(element.totalAmount % 1 === 0 ? "1.0-0" : "1.2-2") }}
              </p>
            </td>
          </ng-container>

          <!--        Actions-->
          <ng-container matColumnDef="actions">
            <th mat-header-cell
                *matHeaderCellDef
                class="!text-white !text-center w-44">Actions
            </th>
            <td mat-cell
                *matCellDef="let element">
              <div class="w-full inline-flex justify-end">
                <button
                  (click)="viewExtraDetail(element)"
                  [disabled]="element.loading"
                  mat-icon-button
                  class="text-darkGray opacity-70"
                  matTooltip="Job Detail">
                  <mat-icon>pageview</mat-icon>
                </button>

                <a
                  [routerLink]="appRoutes.extraJobCheckout({
                    jobId: element.jobId,
                    extraJobId: element.id
                  })"
                  *ngIf="canMakePayment$(element)|async"
                  mat-icon-button
                  class="text-darkGray opacity-70"
                  matTooltip="Make payment">
                  <mat-icon>payment</mat-icon>
                </a>

                <a
                  [matTooltip]="'Proof of Payment'"
                  *ngIf="element.order.payment?.showProofOfPayment"
                  [href]="element.order.payment.proofOfPayment.url"
                  target="_blank"
                  mat-icon-button
                  class="text-darkGray opacity-70">
                  <mat-icon>receipt</mat-icon>
                </a>

                <button
                  [matTooltip]="'Proof of Payment'"
                  *ngIf="element.order.payment?.canGetReceiptUrl"
                  [disabled]="element.order.payment?.loading"
                  (click)="getPaymentReceiptThenOpenIt(element.order.payment)"
                  mat-icon-button
                  class="text-darkGray opacity-70">
                  <mat-icon>receipt</mat-icon>
                </button>

                <button
                  [disabled]="element.loading"
                  (click)="openDialog(element)"
                  *ngIf="canUpdate$(element)|async"
                  mat-icon-button
                  color="accent"
                  matTooltip="Edit job">
                  <mat-icon>edit</mat-icon>
                </button>

                <button
                  [disabled]="element.loading"
                  appConfirmDialog
                  (ok)="onDelete(element)"
                  *ngIf="canDelete$(element)|async"
                  mat-icon-button
                  color="warn"
                  matTooltip="Delete job">
                  <mat-icon>delete</mat-icon>
                </button>

                <button mat-icon-button [matMenuTriggerFor]="pendingAction"
                        *ngIf="element.canConfirm || element.canClose">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #pendingAction="matMenu">
                  <button mat-menu-item
                          *ngIf="element.canConfirm"
                          [disabled]="element.loading"
                          appConfirmDialog
                          (ok)="onConfirm(element)"
                          [confirmMessage]="commonHelper.getMarkJobDoneMessage()">
                    <mat-icon>task</mat-icon>
                    <span>Mark as done</span>
                  </button>
                  <button mat-menu-item
                          *ngIf="element.canClose"
                          [disabled]="element.loading"
                          appConfirmDialog
                          (ok)="onClose(element)"
                          [confirmMessage]="commonHelper.getCancelJobConfirmMessage(activeUser)">
                    <mat-icon>work_off</mat-icon>
                    <span>Close job</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="tableColumns$|async as tableColumns">
            <tr mat-header-row
                *matHeaderRowDef="tableColumns; sticky:true"
                class="bg-primary-800"></tr>
            <tr mat-row
                *matRowDef="let row; columns: tableColumns;"
                class="row-hover"></tr>
            <tr class="mat-row"
                *matNoDataRow>
              <td [colSpan]="tableColumns.length">
                <table-no-record></table-no-record>
              </td>
            </tr>
          </ng-container>
        </table>
      </section>
    </mat-card-content>
  </mat-card>
</div>

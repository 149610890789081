import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonHelper} from "@shared/helpers/common.helper";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";
import {CommonModule} from "@angular/common";
import {CompanyDirector} from "@modules/company/models/company-director.model";
import {LetModule} from "@ngrx/component";
import {Corporate} from "@modules/company/models/corporate.model";
import {MatDividerModule} from "@angular/material/divider";
import {OfficerProfile} from "@modules/officer/models/officer-profile.model";
import {OfficerCompany} from "@modules/officer/models/officer-company.model";
import {OfficerPerson} from "@modules/officer/models/officer-person.model";

export type OfficerInformationItem = Corporate | CompanyDirector

@Component({
  selector: 'app-officer-information',
  standalone: true,
  templateUrl: './officer-information.component.html',
  styleUrls: ['./officer-information.component.scss'],
  imports: [CommonModule, LetModule, MatDividerModule]
})
export class OfficerInformationComponent implements OnInit {
  @Input() officerInfo: OfficerInformationItem

  get item() {
    return this.officerInfo
  }

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly commonHelper: CommonHelper
  ) {
  }

  ngOnInit(): void {
  }

  public getRepresentativeInfo(officerProfile: OfficerProfile): OfficerPerson | null {
    if (officerProfile instanceof OfficerCompany) {
      const {representative} = officerProfile
      if (representative.officerProfile.__typename === "OfficerPersonType") {
        return representative.officerProfile as OfficerPerson
      }
    }
    return null;
  }
}

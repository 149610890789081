import {Injectable} from '@angular/core';
import {
  AccessFileQuery,
  CompanyDocumentsQuery,
  DeleteCompanyDocumentMutation,
  FileType,
  FileTypesQuery,
  FileTypeType,
  FileUrlQuery,
  ReplaceCompanyDocumentMutation,
  UploadCompanyDocumentMutation,
} from '@generated/graphql';
import {BehaviorSubject, catchError, EMPTY, first, map, Observable, tap} from 'rxjs';
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {SnackBarService} from "@shared/services/snack-bar.service";

export type GetFileUrlType = 'DOWNLOAD' | 'URL'

@Injectable({
  providedIn: 'root',
})
export default class FileService {
  public documents$ = new BehaviorSubject<FileType[]>([]);

  constructor(
    private companyDocumentsQuery: CompanyDocumentsQuery,
    private uploadCompanyDocumentMutation: UploadCompanyDocumentMutation,
    private _getFileTypesQuery: FileTypesQuery,
    private _fileUrlQuery: FileUrlQuery,
    private _deleteCompanyDocumentMutation: DeleteCompanyDocumentMutation,
    private _replaceCompanyDocumentMutation: ReplaceCompanyDocumentMutation,
    private accessFileQuery: AccessFileQuery,
    private screenSpinnerService: ScreenSpinnerService,
    private snackBarService: SnackBarService,
  ) {
  }

  getCompanyDocuments(id: string): Observable<FileType[]> {
    return this.companyDocumentsQuery
      .fetch(
        {id},
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((result) => result.data.companyDocuments as FileType[]),
        tap((documents) => this.documents$.next(documents)),
      );
  }

  uploadCompanyDocument(companyId: string, file: File): Observable<FileType> {
    return this.uploadCompanyDocumentMutation
      .mutate(
        {
          companyId,
          file,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(map((result) => result.data.uploadCompanyDocument as FileType));
  }

  downloadFile$(fileId: string): Observable<string> {
    return this.accessFile$(fileId, 'DOWNLOAD')
  }

  viewFile$(fileId: string): Observable<string> {
    return this.accessFile$(fileId, 'URL')
  }

  downloadFile(id: string): void {
    return this.accessFile(id, 'DOWNLOAD')
  }

  viewFile(id: string): void {
    return this.accessFile(id, 'URL')
  }

  public getFileTypes(): Observable<FileTypeType[]> {
    return this._getFileTypesQuery
      .fetch()
      .pipe(map((response) => response.data.fileTypes as FileTypeType[]));
  }

  public getFileUrl(
    fileId: string,
    type: GetFileUrlType,
  ): Observable<string | null> {
    return this._fileUrlQuery
      .fetch({
        fileId,
        type,
      })
      .pipe(map((response) => response.data.fileUrl));
  }

  public deleteCompanyDocument(fileId: string): Observable<boolean> {
    return this._deleteCompanyDocumentMutation
      .mutate({
        fileId,
      })
      .pipe(map((response) => response.data.deleteCompanyDocument));
  }

  public replaceCompanyDocument(
    fileId: string,
    replaceByFile: File,
  ): Observable<FileType> {
    return this._replaceCompanyDocumentMutation
      .mutate(
        {
          fileId,
          replaceByFile,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map((response) => response.data.replaceCompanyDocument as FileType),
      );
  }

  protected accessFile$(fileId: string, type: GetFileUrlType): Observable<string> {
    return this.accessFileQuery.fetch({
      fileId,
      type
    }, {
      fetchPolicy: 'no-cache'
    }).pipe(map(res => res.data.accessFile))
  }

  protected accessFile(id: string, type: GetFileUrlType): void {
    this.screenSpinnerService.setLoading(true)

    this.accessFile$(id, type).pipe(
      first(),
      tap(downloadUrl => {
        this.screenSpinnerService.setLoading(false)

        window.open(downloadUrl, '_blank')
      }),
      catchError(e => {
        this.screenSpinnerService.setLoading(false)

        this.snackBarService.pushErrorMessage()

        return EMPTY
      })
    ).subscribe()
  }
}

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
  ConfirmDialogAction,
  ConfirmDialogComponent,
  ConfirmDialogComponentData
} from '@shared/component/dialog/confirm-dialog/confirm-dialog.component';
import { tap } from 'rxjs';

@Directive({
  selector: '[appConfirmDialog]',
  providers: [MatDialogModule]
})
export class ConfirmDialogDirective {
  @Input() useDialog: boolean = true
  @Input() confirmMessage: string;
  @Input() title: string;
  @Output() confirmed: EventEmitter<ConfirmDialogAction> = new EventEmitter<ConfirmDialogAction>();
  @Output() ok: EventEmitter<ConfirmDialogAction.Confirm> = new EventEmitter<ConfirmDialogAction.Confirm>();
  @Output() cancel: EventEmitter<ConfirmDialogAction.Cancel> = new EventEmitter<ConfirmDialogAction.Cancel>();

  constructor (
    protected readonly matDialog: MatDialog
  ) {
  }

  @HostListener('click')
  protected confirm (): void {
    const {title, confirmMessage} = this;

    if (this.useDialog) {
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        minWidth:'30%',
        maxWidth: '40%',
        autoFocus: false,
        data: {title, confirmMessage} as ConfirmDialogComponentData
      });

      dialogRef.afterClosed().pipe(
        tap((action: ConfirmDialogAction | null) => {
          this.handleAction(action)
        })).subscribe();
    } else {
      this.handleAction(ConfirmDialogAction.Confirm)
    }
  }

  handleAction (action: ConfirmDialogAction | null) {
    const finalAction = action ? action : ConfirmDialogAction.Cancel

    this.confirmed.emit(finalAction);

    switch (finalAction) {
      case ConfirmDialogAction.Confirm: {
        this.ok.emit(ConfirmDialogAction.Confirm)
        break;
      }
      case ConfirmDialogAction.Cancel: {
        this.cancel.emit(ConfirmDialogAction.Cancel)
        break;
      }
      default: {
      }
    }
  }
}

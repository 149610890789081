import {AbstractControl, ValidationErrors, Validators as BaseValidators} from '@angular/forms';
import {APP_INITIALIZER, Provider} from '@angular/core';
import {MailValidator} from '@utils/validation/app.validation';
import {DateTime} from "luxon";
import {isNumber} from "lodash-es";

export class Validators extends BaseValidators {
  static readonly swiftOrBicPattern = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

  static email = MailValidator;

  static swiftOrBic = (control: AbstractControl): ValidationErrors => {
    const code = control.value as string;

    if (!code) {
      return null;
    }

    return this.swiftOrBicPattern.test(code)
      ? null
      : {
        code: {
          longMessage: 'A SWIFT code consists of 8 or 11 characters: 4 for bank, 2 for country, 2 for location, and optional 3 for branch.',
          message: 'Code is invalid',
        },
      };
  };

  static bankAccountNumber = (field: string = 'accountNumber') => (control: AbstractControl): ValidationErrors => {
    const minLengthError = this.minLength(5)(control);

    const maxLengthError = this.maxLength(17)(control);

    if (minLengthError || maxLengthError) {
      return {
        [field]: Object.assign({message: 'Bank account numbers have between 5 and 17 digits'}, minLengthError, maxLengthError)
      };
    }

    return null;
  };

  static biggerThan = (number: number) => (control: AbstractControl): ValidationErrors => {
    if (isNumber(control.value) && control.value <= number) {
      return {
        biggerThan: {
          message: `Value should be bigger than ${number}`,
          number
        }
      };
    }

    return null;
  };
}

export const APP_VALIDATORS_CONFIG = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory() {
    return (): void => {
      BaseValidators.email = Validators.email;
    };
  },
} as Provider;


export const hasMatDatePickerError = (control: AbstractControl) => control.hasError('matDatepickerMin') || control.hasError('matDatepickerMax') || control.hasError('matDatepickerParse');

import {ModelConstructor} from "@shared/models/base.model";
import {CompanyGstReturnItemType, QuarterEnum} from "@generated/graphql";
import {GstReturn} from "@modules/gst-return/models/gst-return.model";
import {gstReturnFactory} from "@modules/gst-return/factories/gst-return.factory";
import {Job} from "@modules/job/models/job.model";
import {jobFactory} from "@modules/job/factories/job.factory";
import {startCase} from "lodash-es";

export class CompanyGstReturnItem extends ModelConstructor implements CompanyGstReturnItemType {
  canSubmit: boolean;
  canUpload: boolean;
  deadline: Date;
  quarter: QuarterEnum;
  year: number;
  gstReturn?: GstReturn
  job?: Job

  showInitialError(): void {
    throw new Error('Something wrong! when create new ' + CompanyGstReturnItem.name + ' instance');
  }

  constructor(state: CompanyGstReturnItemType) {
    super(state);

    this.gstReturn = gstReturnFactory.tryCreating(state.gstReturn)

    this.job = jobFactory.tryCreating(state.job)
  }

  get isDeadlinePast () {
    return new Date(this.deadline) < new Date(Date.now())
  }

  get quarterAndYear (): string {
    return startCase(this.quarter) + ' - ' + this.year
  }
}

import { Injectable } from '@angular/core';
import {
  JobsQuery,
  PaginationInput,
  JobQuery,
  JobType,
  SyncUsersToJobInput,
  SyncCustomersToJobMutation,
  SyncExpertsToJobMutation,
  JobSortKeyInput
} from '@generated/graphql';
import { map, Observable } from 'rxjs';
import { PaginationData } from '@shared/types/pagination.type';
import { Job } from '@modules/job/models/job.model';
import { paginationDataFactory } from '@shared/factories/pagination.factoy';
import { jobFactory } from '@modules/job/factories/job.factory';
import { FindContract } from "@shared/contracts/find.contract";

@Injectable({
  providedIn: 'root',
})
export class JobApi implements FindContract<Job> {
  constructor (
    protected readonly jobsQuery: JobsQuery,
    protected readonly jobQuery: JobQuery,
    protected readonly syncCustomersToJobMutation: SyncCustomersToJobMutation,
    protected readonly syncExpertsToJobMutation: SyncExpertsToJobMutation,
    // protected readonly updateJobMutation: Update
  ) {
  }

  find$ (id: string): Observable<Job> {
    return this.jobQuery.fetch({id}, {fetchPolicy: 'no-cache'}).pipe(
      map(res => res.data.job ? jobFactory.create(res.data.job as JobType) : null)
    )
  }

  syncCustomersToJob$ (input: SyncUsersToJobInput): Observable<Job> {
    return this.syncCustomersToJobMutation.mutate({input}).pipe(
      map(res => res.data.syncCustomersToJob ? jobFactory.create(res.data.syncCustomersToJob as JobType) : null)
    )
  }

  syncExpertsToJob$ (input: SyncUsersToJobInput): Observable<Job> {
    return this.syncExpertsToJobMutation.mutate({input}).pipe(
      map(res => res.data.syncExpertsToJob ? jobFactory.create(res.data.syncExpertsToJob as JobType) : null)
    )
  }

  getJobs$ (pagination: PaginationInput, searchText: string = '', sorts: JobSortKeyInput[] = []): Observable<PaginationData<Job>> {
    return this.jobsQuery.fetch({
      pagination,
      searchText,
      sorts
    }, {
      fetchPolicy: 'no-cache',
    }).pipe(
      map(res => paginationDataFactory.create(res.data.jobs, jobFactory)),
    );
  }
}

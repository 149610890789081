import { Inject, Injectable } from '@angular/core';
import {
  CheckCanStartAcceptingPaymentQuery,
  PaymentProviderNameEnum,
  PaymentsForCompanyQuery,
  PaymentType,
  UploadProofOfPaymentInput,
  UploadProofOfPaymentMutation,
} from '@generated/graphql';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import { EntityFeatureNameEnum } from '@shared/contracts/entity-store.contract';
import { Payment } from '@modules/payment/models/payment.model';
import {
  PAYMENT_STORE,
  PaymentStore,
} from '@modules/payment/store/payment.store';
import { Store } from '@ngrx/store';
import { PaymentApi } from '@modules/payment/api/payment.api';
import {
  paymentFactory,
  PaymentFactory,
} from '@modules/payment/factories/payment.factory';
import { CommonService } from '@shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends EntityService<
EntityFeatureNameEnum.Payment,
PaymentType,
Payment
> {
  constructor(
    @Inject(PAYMENT_STORE) protected readonly entityStore: PaymentStore,
    protected readonly store: Store,
    private readonly paymentApi: PaymentApi,
    private readonly uploadProofOfPaymentMutation: UploadProofOfPaymentMutation,
    private readonly _checkCanStartAcceptingPayment: CheckCanStartAcceptingPaymentQuery,
    private readonly _paymentsForCompanyQuery: PaymentsForCompanyQuery,
  ) {
    super(store, entityStore);
  }

  get factory(): PaymentFactory {
    return paymentFactory;
  }

  getPaymentReceiptThenOpenIt$(
    payment: Payment,
    commonService?: CommonService,
  ) {
    const errorMsg =
      "Can't get Payment Receipt. Please try again or contact us to support!";

    payment.setLoading(true);

    return this.paymentApi.getPaymentWithOnlyReceipt$(payment.id).pipe(
      tap((paymentWithOnlyReceipt) => {
        payment.setLoading(false);

        if (paymentWithOnlyReceipt) {
          window.open(paymentWithOnlyReceipt.receiptUrl);

          return;
        }

        commonService?.snackBarService.pushErrorAlert(errorMsg);
      }),
      catchError((e) => {
        commonService?.snackBarService.pushErrorAlert(errorMsg);

        payment.setLoading(false);

        return EMPTY;
      }),
    );
  }

  uploadProofOfPayment(data: UploadProofOfPaymentInput) {
    return this.uploadProofOfPaymentMutation
      .mutate(
        {
          input: {
            ...data,
          },
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(map((result) => result.data.uploadProofOfPayment));
  }

  checkCanStartAcceptingPayment(provider: PaymentProviderNameEnum) {
    return this._checkCanStartAcceptingPayment
      .fetch({ provider }, { fetchPolicy: 'network-only' })
      .pipe(map((res) => res.data.checkCanStartAcceptingPayment));
  }

  public getPaymentsForCompany(companyId: string): Observable<PaymentType[]> {
    return this._paymentsForCompanyQuery
      .fetch(
        {
          companyId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((response) => response.data.paymentsForCompany as PaymentType[]),
      );
  }
}

<div class="shareholder-dialog-wrapper w-full" *ngIf="activeUser$ | async as activeUser">
  <div class="w-full inline-flex justify-between items-start">
    <h1 mat-dialog-title class="mb-2 text-darkGray !text-[2.2rem]">
      <span>{{ officerDialogManagement.title }}</span>
    </h1>
    <button mat-button color="primary" (click)="onOpenLookupOfficer()" *ngIf="activeUser.isExpert()">
      <span class="underline">
        Lookup
        <span *ngIf="officerDialogManagement.isShareholder()">Shareholder</span>
        <span *ngIf="officerDialogManagement.isDirector()">Director</span>
      </span>
    </button>
  </div>

  <mat-dialog-content class="shareholders-container">
    <div class="flex flex-col" *ngIf="
        activeUser.isCustomer() ||
        (!activeUser.isCustomer() && officerDialogManagement.isDirector())
      ">
      <p class="mat-title !text-base text-darkGray !mb-2">Use Detail of</p>
      <mat-radio-group aria-label="Select an option" class="group-selection mb-4 inline-flex" [(ngModel)]="useDetailOf"
        [ngModelOptions]="{ standalone: true }">
        <mat-radio-button class="select-option mr-4 !transform:capitalize" *ngFor="let option of useDetailOfOption"
          [value]="option.value" (change)="onSelectUseDetailChange($event)">
          <p class="mat-subheading-1 text-darkGray !mb-0 text-transform: capitalize">
            {{ option.label }}
          </p>
        </mat-radio-button>
      </mat-radio-group>

      <app-exists-officers-select *ngIf="
          (activeUser.isCustomer() &&
            useDetailOf === 'USE_EXISTING' &&
            (fetchingCustomerOfficers$ | async) === false) ||
          (!activeUser.isCustomer() && useDetailOf === 'USE_EXISTING')
        " [countryOfIncorporation]="countryOfIncorporation" [existOfficers]="officerDialogManagement.existOfficers"
        [refId]="refId" [exceptIds]="data.exceptIds"
        (existsOfficerSelectedEvent)="existsOfficerSelectedHandler($event)"></app-exists-officers-select>

      <mat-divider class="!my-4 w-1/2 !m-auto"></mat-divider>
    </div>

    <!--    Type-->
    <div class="flex flex-col mb-4">
      <p class="mat-title !text-base text-darkGray !mb-2">Type</p>
      <mat-radio-group aria-label="Select an option" class="group-selection mb-4 inline-flex" [(ngModel)]="selectedType"
        [ngModelOptions]="{ standalone: true }" [disabled]="useDetailOf === 'USE_EXISTING'">
        <ng-container *ngFor="let option of officerTypeOption">
          <mat-radio-button class="select-option mr-4 !transform:capitalize" [value]="option.value"
            (change)="onSelectionChange($event)" *ngIf="showCorporate(option)">
            <p class="mat-subheading-1 text-darkGray !mb-0 text-transform: capitalize">
              {{ option.label }}
            </p>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
    <!--  Personal Information-->
    <div *ngIf="selectedType === officerType.Individual">
      <p class="mat-title !text-base text-darkGray !mb-1">
        <span>{{ officerDialogManagement.informationLabel }}</span>
      </p>
      <app-individual-info *ngIf="officerDialogManagement.officerFormManagement"
        [formGroup]="officerDialogManagement.getOfficerForm()"></app-individual-info>
    </div>
    <!--    Corporate Information-->
    <app-corporate-info *ngIf="
        officerDialogManagement.officerFormManagement &&
        selectedType === officerType.Corporate
      " [formGroup]="officerDialogManagement.getOfficerForm()"></app-corporate-info>
    <!--    share allotment-->
    <section class="share-distribution-wrapper" *ngIf="
        officerDialogManagement.isShareholder() &&
        officerDialogManagement.officerFormManagement
      ">
      <mat-divider class="!my-4"></mat-divider>
      <p class="col-span-2 mat-title !text-base text-darkGray !mb-2">
        Shares Distribution
      </p>
      <app-shares-distribution-table [formGroup]="getShareDistributionsForm()" [companyCapitals]="companyCapitals"
        [shareholderControls]="getShareholderControls()"
        [shareholderEditIndex]="data.shareholderEditIndex"></app-shares-distribution-table>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button class="bg-grey-900 text-white w-52" (click)="onCloseDialog()">
      Cancel
    </button>
    <button mat-raised-button class="w-52 text-white bg-primary-900"
      [disabled]="!officerDialogManagement.isOfficerFormValid()" [mat-dialog-close]="getReturnResult()">
      Save
    </button>
  </mat-dialog-actions>
</div>

<ng-container>
  <section class="mb-4">
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Current Director Information
    </p>
    <app-officer-input-review-information [officerType]="'CompanyDirectorType'" [formType]="getOfficerType()"
      [officerInput]="getCurrentOfficerInputValue()"></app-officer-input-review-information>
  </section>
  <div>
    <p class="mat-title !text-base !mb-3 p-2 text-primary-900 bg-grey-50">
      Removal reason
    </p>
    <p class="mb-0 pl-2 normal-case mat-subheading-1 !text-sub">
      &bullet;&nbsp;{{ getReason() }}
    </p>
  </div>
</ng-container>

export const environment = {
    apiUrl: 'https://api.staging.skynet.becorps.net/graphql',
    production: false,
    appDBName: 'becorps_tenant_database',
    appStore: {
      debug: true
    },
    stripe: {
      publishableKey: 'pk_test_51K7dJTJVYJ7haL2rIqPSfoye9WEEyZYBk0yyYaGjqhxcvHm6PbhNGOxH7mbqXUEWRwvPM42sZmW7QOE4jCxeyEGP00PtKEvrSB'
    },
    firebaseConfig: {
      apiKey: 'AIzaSyBLMk8qJDp9Nx-mEcy2K5wtZAGrPx2JzTk',
      authDomain: 'becorps-a9255.firebaseapp.com',
      databaseURL:
        'https://becorps-a9255-default-rtdb.asia-southeast1.firebasedatabase.app',
      projectId: 'becorps-a9255',
      storageBucket: 'becorps-a9255.appspot.com',
      messagingSenderId: '703304480838',
      appId: '1:703304480838:web:7ec4d8a397686ad2c67247',
      measurementId: 'G-34BD0F2SXY',
    },
    pusher: {
      appKey: 'c52961622af686a909e7',
      cluster: 'ap1',
    },
  };
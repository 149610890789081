import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {PaymentProviderNameEnum, UploadProofOfPaymentInput,} from '@generated/graphql';
import {BehaviorSubject, catchError, EMPTY, filter, first, map, Observable, of, switchMap, tap,} from 'rxjs';
import {Country} from '@modules/country/models/country.model';
import {WireTransferBankService} from '@modules/wire-transfer-bank/services/wire-transfer-bank.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WireTransferBank} from '@modules/wire-transfer-bank/models/wire-transfer-bank.model';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {WireTransferFormType} from '../../models/forms/wire-transfer-form-type';
import {Router} from '@angular/router';
import {
  DropzoneAction,
  DropzoneFileChangedEvent,
} from '@shared/component/common/dropzone-upload/dropzone-upload.component';
import {PaymentService} from '../../services/payment.service';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {
  BusinessProfileService
} from '@modules/account-settings/pages/business-profile/services/business-profile.service';
import {PaymentMethodsService} from '@shared/component/payments/components/payment-methods/payment-methods.service';
import {PaymentMethodsData} from '@shared/component/payments/components/payment-methods/payment-methods.type';
import {BillingAddressService} from '@modules/address/services/billing-address.service';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {OrderBillingAddress} from '@app/modules/order/models/order-billing-address.model';
import {PaymentProviderService} from "@modules/payment/services/payment-provider.service";
import {MatRadioChange} from "@angular/material/radio";

@UntilDestroy()
@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  @Input() country$: Observable<Country>;

  readonly PaymentProviderNameEnum = PaymentProviderNameEnum;

  banks$: Observable<WireTransferBank[]> = of([]);

  paymentProviderControl = new FormControl(null, [
    Validators.required
  ])

  wireTransferForm: FormGroup<WireTransferFormType>;

  paymentProviderOptions$ = this.paymentProviderService.paymentProviderOptions$

  submitting = new BehaviorSubject(false);

  businessProfile$ = this.businessProfileService.activeEntity$;

  get disabledSubmitWireTransferPayment$(): Observable<boolean> {
    return this.billingAddressService.activeEntity$.pipe(
      map((billingAddress) => {
        return (
          !billingAddress ||
          this.wireTransferForm?.invalid ||
          this.submitting.value ||
          !this.paymentProviderControl.value
        );
      }),
    );
  }

  get paymentMethodsData$(): Observable<PaymentMethodsData> {
    return this.paymentMethodsService.data$;
  }

  constructor(
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
    private readonly paymentMethodsService: PaymentMethodsService,
    private readonly router: Router,
    private readonly wireTransferBankService: WireTransferBankService,
    private readonly fb: FormBuilder,
    private readonly paymentService: PaymentService,
    private readonly paymentProviderService: PaymentProviderService,
    private readonly snackbarService: SnackBarService,
    private readonly businessProfileService: BusinessProfileService,
    private readonly billingAddressService: BillingAddressService,
  ) {
  }

  ngOnInit(): void {
    this.paymentProviderOptions$.pipe(
      untilDestroyed(this),
      filter(items => !!items?.length && !this.paymentProviderControl.value),
      tap(items => {
        this.paymentProviderControl.setValue(items[0].value)
      })
    ).subscribe()

    this.country$
      .pipe(
        untilDestroyed(this),
        tap((country) => {
          if (country) {
            this.banks$ = this.wireTransferBankService.getByCountryId$(
              country.id,
            );

            return;
          }

          this.banks$ = of([]);
        }),
      )
      .subscribe();

    this.createWireTransferForm();

    this.paymentMethodsData$
      .pipe(
        untilDestroyed(this),
        filter((data) => !!data),
        tap((value) => {
          this.wireTransferForm.patchValue({
            orderId: value.order.id,
          });
        }),
      )
      .subscribe();
  }

  createWireTransferForm(): void {
    this.wireTransferForm = this.fb.group({
      orderId: new FormControl(null),
      file: new FormControl(null, [Validators.required]),
    });
  }

  resetWireTransferForm(): void {
    this.wireTransferForm.patchValue({
      orderId: this.paymentMethodsService.data.order.id,
      file: null,
    });
  }

  wireTransferProofChanged(event: DropzoneFileChangedEvent): void {
    if (event.type === DropzoneAction.Added) {
      this.wireTransferForm.controls.file.setValue(event.files[0]);
    } else if (event.type === DropzoneAction.Removed) {
      this.wireTransferForm.controls.file.setValue(null);
    }
  }

  onPaymentOptionChange(change: MatRadioChange) {
    this.paymentProviderControl.setValue(change.value)

    this.resetWireTransferForm();
  }

  submitPayment(): void {
    switch (this.paymentProviderControl.value) {
      case PaymentProviderNameEnum.WireTransfer: {
        this.submitWireTransfer();

        break;
      }
      default: {
        this.snackbarService.pushWarningAlert('Payment method not available');
      }
    }
  }

  submitWireTransfer(): void {
    this.submitting.next(true);

    this.billingAddressService.selectedBillingAddress$
      .pipe(
        first(),
        switchMap((address) => {
          if (address instanceof BillingAddress) {
            return this.uploadProofOfPayment$({
              ...this.wireTransferForm.value,
              billingAddressId: address?.id,
            });
          } else if (address instanceof OrderBillingAddress) {
            return this.uploadProofOfPayment$({
              ...this.wireTransferForm.value,
            });
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  uploadProofOfPayment$(input: UploadProofOfPaymentInput) {
    return this.paymentService.uploadProofOfPayment(input).pipe(
      tap((result) => {
        if (result) {
          this.snackbarService.pushSubmitPaymentSuccessMessage();
          this.router.navigate(
            this.paymentMethodsService.data?.redirectUrl ??
            this.appRoutes.dashboard(),
          );
        } else {
          this.snackbarService.pushSubmitPaymentFailMessage();
        }

        this.submitting.next(false);
      }),
      catchError((error) => {
        this.snackbarService.pushErrorMessage();
        this.submitting.next(false);

        return error;
      }),
    );
  }

  ngOnDestroy(): void {
    this.submitting.unsubscribe();
  }
}

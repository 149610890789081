import {OfficerTypeEnum} from "@generated/graphql";
import {SelectOption} from "@shared/helpers/convention.helpers";
import {OfficerRoleEnum} from "@shared/types/officerEnum";
import {UseDetailOfEnum} from "@shared/types/commonEnum";

export const OFFICER_TYPES: SelectOption[] = [
  {
    label: OfficerTypeEnum.Individual.toString()
      .toLowerCase(),
    value: OfficerTypeEnum.Individual,
  },
  {
    label: OfficerTypeEnum.Corporate.toString()
      .toLowerCase(),
    value: OfficerTypeEnum.Corporate,
  },
]


export const USE_DETAIL_OF = (officerRole?: OfficerRoleEnum): SelectOption[] => {
  return [
    {
      label: `New ${officerRole ? officerRole.toString().toLowerCase() : ''}`,
      value: UseDetailOfEnum.New,
    },
    {
      label: 'Existing Shareholders / Directors',
      value: UseDetailOfEnum.Existing,
    }]
}

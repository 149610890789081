import { BaseModel } from '@shared/models/base.model';
import { FileType, MetadataType } from '@generated/graphql';
import { User } from '@app/modules/user/models/user.model';
import { FileType as FileTypeModel } from './file-type.model';
import { fileTypeFactory } from "@modules/file/factories/file-type.factory";
import { userFactory } from "@modules/user/factories/user.factory";

export class File extends BaseModel implements FileType {
  static readonly defaultMimes: string[] = [
    '.jpeg',
    '.png',
    '.jpg',
    '.gif',
    '.svg',
    '.xlsx',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.ods',
    '.odt',
    '.odp',
    '.pdf',
    '.csv',
    '.excel'
  ];

  id: string;
  createdBy: User;
  fileTypeId: string;
  fileType: FileTypeModel;
  hasFileId: string;
  hasFileType: string;
  metadata: MetadataType;
  size: number;
  name: string;
  url: string;
  visibility: boolean;
  downloadUrl: string;
  _model: string;

  constructor (state: FileType) {
    super(state);

    this.fileType = fileTypeFactory.tryCreating(state.fileType)

    this.createdBy = userFactory.tryCreating(state.createdBy)
  }

  getModelName (): string {
    return File.name;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidControlScrollDirective } from 'src/shared/share-directives/directives/invalid-control-scroll.directive';
import { ConfirmDialogDirective } from './directives/confirm-dialog.directive';


@NgModule({
  declarations: [
    InvalidControlScrollDirective,
    ConfirmDialogDirective,
  ],
  exports: [
    InvalidControlScrollDirective,
    ConfirmDialogDirective,
  ],
  imports: [
    CommonModule,
  ],
})
export class ShareDirectivesModule {
}

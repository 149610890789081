<ng-container *ngrxLet="{
    activeUser: activeUser$ | async,
    appState: conversationManagement.appState$ | async,
    uploadStatus: conversationManagement.uploadStatus$ | async,
    isMissingExperts:
      conversationManagement.memberManagement.isMissingExperts$ | async
  } as context">
  <div class="conversation-wrapper h-full">
    <mat-card class="!flex !flex-row !w-full !h-full !p-0 overflow-hidden">
      <!--      MESSAGE GROUP-->
      <section class="flex flex-col flex-shrink w-full !pl-4 !pb-4 overflow-hidden">
        <div *ngIf="
            context.appState === 'finished' &&
            keys(conversationManagement.groupedMessages).length
          ">
          <div class="chat-header flex flex-row w-full justify-end p-2">
            <button mat-icon-button class="!mr-2" appConfirmDialog [confirmMessage]="
                'It seems like the recipient might have overlooked your previous message. Send a friendly reminder to keep the conversation on track.\n Click Confirm to send a reminder notification'
              " (ok)="manualSendNotification()" [disabled]="notifying$ | async"
                    [matTooltip]="'Send remind notification'">
              <mat-icon class="text-darkGray opacity-70 !text-[2.8rem]">
                campaign
              </mat-icon>
            </button>
          </div>
          <mat-divider class="!-ml-4"></mat-divider>
        </div>
        <!--MESSAGE SECTION-->
        <div class="chat-container flex flex-col w-full h-full pt-3">
          <!--MESSAGE DISPLAY GROUP-->
          <section class="chat-messages-container h-full pr-5" #messagesContainer>
            <!-- CHECKING SERVICE -->
            <div *ngIf="context.appState === 'initializing'" class="flex flex-col justify-center items-center h-[100%]">
              <mat-spinner diameter="36"></mat-spinner>
              <p class="text-darkGray">Initializing...</p>
            </div>

            <!-- MISSING EXPERTS -->
            <div *ngIf="context.isMissingExperts" class="flex flex-col justify-center items-center h-[100%]">
              <div class="w-full text-center mt-3">
                <mat-icon class="text-darkGray !w-12 !h-12 !leading-[4.8rem] !text-[4.8rem]">
                  speaker_notes_off
                </mat-icon>
                <p class="text-lightGray !text-base !mb-1">
                  <span *ngIf="context.activeUser.isCustomer()">
                    Chat is unavailable until an expert is assigned to your
                    request. We will reach out to you as soon as possible.
                  </span>
                  <span *ngIf="
                      context.activeUser.isSuperAdmin() ||
                      context.activeUser.isAdmin()
                    ">
                    Chat is currently unavailable. Assign expert to start chat
                  </span>
                </p>
              </div>
            </div>

            <!-- SERVICE IS READY -->
            <div *ngIf="
                !context.isMissingExperts &&
                (context.appState === 'get_members' ||
                  context.appState === 'get_messages')
              " class="flex flex-col justify-center items-center h-[100%]">
              <mat-spinner diameter="36"></mat-spinner>
            </div>

            <ng-container *ngIf="
                !context.isMissingExperts && context.appState === 'finished'
              ">
              <div *ngIf="
                  keys(conversationManagement.groupedMessages).length === 0
                " class="flex flex-col justify-center items-center h-[100%]">
                <p class="!mb-0">
                  <mat-icon class="text-darkGray !w-12 !h-12 !leading-[4.8rem] !text-[4.8rem]">comments_disabled
                  </mat-icon>
                </p>
                <p class="text-darkGray">No messages</p>
              </div>

              <!--              BLOCK MESSAGE-->
              <ng-container *ngIf="keys(conversationManagement.groupedMessages).length">
                <div class="message-block flex flex-col" *ngFor="
                    let group of conversationManagement.groupedMessages
                      | keyvalue
                  ">
                  <div class="flex flex-row w-full items-center !mb-4">
                    <mat-divider class="w-full"></mat-divider>
                    <p class="!mb-0 font-['OpenSans_Semibold'] text-lightGray !text-sub whitespace-nowrap !mx-4">
                      {{ group.key }}
                    </p>
                    <mat-divider class="w-full"></mat-divider>
                  </div>

                  <ng-container *ngFor="let message of group.value">
                    <app-message-wrapper [message]="message" [id]="'messageId_' + message.id"
                                         (gotoMessage)="gotoMessageHandler($event)"
                                         (messageSelected)="onActionOnMessageSelected($event)"
                                         (deleteMessage)="deleteMessageHandler(message)">
                    </app-message-wrapper>
                  </ng-container>

                  <!-- <p class="pr-10 !mb-0 !text-sm text-lightGray"> -->
                  <!--   [status: sent/delivered/seen] -->
                  <!-- </p> -->
                </div>
              </ng-container>
            </ng-container>
          </section>

          <!--CHAT INPUT-->
          <ng-container *ngIf="!context.isMissingExperts && context.appState === 'finished'">
            <mat-divider class="!-ml-8 !my-4"></mat-divider>

            <section class="chat-input-container w-full pr-5">
              <div class="chat-custom bg-[#f0f2f5]">
                <ng-container *ngIf="messageSelected$ | async as messageSelected">
                  <section class="p-3 w-full pb-0 relative">
                    <p class="font-['OpenSans_Medium'] !mb-1 text-sub text-secondary-default">
                      <span *ngIf="
                          messageSelected.action === MessageActionEnum.Reply
                        ">
                        Replying to
                        {{
                          messageSelected.message.isMe
                            ? "yourself"
                            : messageSelected.message.sender.displayName
                        }}
                      </span>
                      <span *ngIf="
                          messageSelected.action === MessageActionEnum.Edit
                        ">
                        Edit Message
                      </span>
                    </p>
                    <p class="!mb-0 text-sub truncate text-lightGray w-[90%]">
                      {{ messageSelected.message.body }}
                    </p>
                    <button mat-icon-button class="!absolute !w-6 !h-6 !leading-[2.4rem] top-3 right-3"
                            (click)="onCancelAction()">
                      <mat-icon class="text-darkGray opacity-70 !text-base">
                        close
                      </mat-icon>
                    </button>
                    <mat-divider class="!mt-2"></mat-divider>
                  </section>
                </ng-container>
                <!--File UPLOAD SECTION-->
                <div class="flex flex-row p-4 file-upload-container" *ngIf="showUpload">
                  <div class="flex justify-center items-center">
                    <button mat-raised-button
                            class="!shadow-none !rounded-[0.4rem] !min-w-[4.8rem] !w-12 !h-12 bg-[#3a4856b3] hover:!bg-darkGray"
                            (click)="dropzone.showFileSelector()" [disabled]="context.uploadStatus === 'uploading'">
                      <img src="assets/images/icons/add-file.svg" class="w-6 h-6 object-cover !text-white"
                           alt="upload-file"/>
                    </button>
                  </div>
                  <div ngx-dropzone
                       class="!border-0 !bg-transparent !min-h-0 pl-2 gap-x-2 !flex-nowrap !overflow-visible"
                       [maxFileSize]="5242880" [expandable]="true" (change)="onSelect($event)" #dropzone>
                    <mat-progress-bar *ngIf="context.uploadStatus === 'uploading'"
                                      mode="indeterminate"></mat-progress-bar>
                    <ngx-dropzone-preview class="custom-dropzone-preview" *ngFor="let f of conversationManagement.files"
                                          [removable]="context.uploadStatus !== 'uploading'" (removed)="onRemove(f)">
                      <ngx-dropzone-label class="flex justify-center items-center" [matTooltip]="f.name">
                        <mat-icon>description</mat-icon>
                      </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </div>
                </div>
                <div [formGroup]="conversationManagement.form" class="input-zone mx-3 my-2">
                  <mat-form-field appearance="outline" class="w-full">
                    <textarea class="w-full !text-sub" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" contenteditable="true"
                              placeholder="Enter your messages" formControlName="body" (keyup.enter)="
                        conversationManagement.form.invalid
                          ? null
                          : submitHandler()
                      ">
                    </textarea>
                    <div matSuffix class="flex flex-row">
                      <button mat-icon-button class="mr-4" (click)="toggleUpload()">
                        <mat-icon class="text-darkGray opacity-80">attach_file</mat-icon>
                      </button>
                      <mat-divider vertical class="!my-2"></mat-divider>
                      <button mat-icon-button color="primary" (click)="submitHandler()">
                        <mat-icon> send</mat-icon>
                      </button>
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </section>
          </ng-container>
        </div>
      </section>
      <mat-divider vertical class="!-mt-4 !-mb-4"></mat-divider>
      <!--      CHAT INFO-->
      <section class="basis-96 flex-shrink-0 w-full !pl-8 !pr-4 py-4">
        <app-conversation-info [job]="job"
                               [members$]="conversationManagement.memberManagement.members$"></app-conversation-info>
      </section>
    </mat-card>
  </div>
</ng-container>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Job} from '@modules/job/models/job.model';
import {OfficerTypeEnum, ServiceEnum} from '@generated/graphql';
import {CommonHelper} from '@shared/helpers/common.helper';
import {
  CompanyIncorporationInfoComponent
} from './components/company-incorporation-info/company-incorporation-info.component';
import {
  CompanyRegisteredAddressInfoComponent
} from './components/company-registered-address-info/company-registered-address-info.component';
import {DynamicComponentDirective} from '@shared/share-directives/directives/dynamic-component.directive';
import {
  OfficerInputReviewInformationComponent
} from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import {UpdateDirectorInfoComponent} from './components/update-director-info/update-director-info.component';
import {
  IncreaseCapitalInfoComponent
} from '@modules/job/components/job-detail/main-request/components/increase-capital-info/increase-capital-info.component';
import {RemoveDirectorInfoComponent} from './components/remove-director-info/remove-director-info.component';
import {ReplaceDirectorInfoComponent} from './components/replace-director-info/replace-director-info.component';
import {UpdateShareholderInfoComponent} from './components/update-shareholder-info/update-shareholder-info.component';
import {TransferSharesInfoComponent} from './components/transfer-shares-info/transfer-shares-info.component';
import {
  SpecialRequestInfoComponent
} from '@modules/job/components/job-detail/main-request/components/special-request-info/special-request-info.component';
import {
  ChangeCompanyStatusReviewComponent
} from './components/change-company-status-review/change-company-status-review.component';
import {
  ChangeFyeDateInfoComponent
} from '@modules/job/components/job-detail/main-request/components/change-fye-date-info/change-fye-date-info.component';
import {
  GstRegistrationInfoComponent
} from '@modules/job/components/job-detail/main-request/components/gst-registration-info/gst-registration-info.component';
import {
  GstReturnInfoComponent
} from '@modules/job/components/job-detail/main-request/components/gst-return-info/gst-return-info.component';
import {TaxReturnReviewInfoComponent} from './components/tax-return-review-info/tax-return-review-info.component';
import {HoldAgmReviewInfoComponent} from './components/hold-agm-review-info/hold-agm-review-info.component';

@Component({
  selector: 'app-job-main-request',
  templateUrl: './main-request.component.html',
  styleUrls: ['./main-request.component.scss'],
})
export class MainRequestComponent implements OnInit {
  @ViewChild(DynamicComponentDirective, {static: true})
  dynamicComponent!: DynamicComponentDirective;
  ServiceEnum = ServiceEnum;

  private _job: Job;

  @Input()
  get job() {
    return this._job;
  }

  set job(value: Job) {
    this._job = value;
  }

  constructor(protected readonly commonHelper: CommonHelper) {
  }

  ngOnInit(): void {
    this.loadComponent();
  }

  private loadComponent() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;

    viewContainerRef.clear();

    if (this.job) {
      // COMPANY INCORPORATION REQUEST
      if (
        this.job.service.name === ServiceEnum.CompanyRegistration ||
        this.job.service.name === ServiceEnum.TransferCompany
      ) {
        const componentRef = viewContainerRef.createComponent(
          CompanyIncorporationInfoComponent,
        );

        componentRef.instance.job = this.job;
      } else if (this.job.service.name === ServiceEnum.DormantApplication) {
        const componentRef = viewContainerRef.createComponent(
          ChangeCompanyStatusReviewComponent,
        );

        componentRef.instance.job = this.job;
      }
      // CHANGE REGISTER ADDRESS REQUEST
      else if (this.job.service.name === ServiceEnum.ChangeRegisteredAddress) {
        const componentRef = viewContainerRef.createComponent(
          CompanyRegisteredAddressInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
      // ADD NEW DIRECTOR REQUEST
      else if (this.job.service.name === ServiceEnum.AddDirector) {
        const componentRef = viewContainerRef.createComponent(
          OfficerInputReviewInformationComponent,
        );

        componentRef.instance.officerInput =
          this.job.detailObject.directorInput.director;

        componentRef.instance.officerType = 'CompanyDirectorType';

        if (this.job.detailObject.directorInput.director.officerCompany) {
          componentRef.instance.formType = OfficerTypeEnum.Corporate;
        } else {
          componentRef.instance.formType = OfficerTypeEnum.Individual;
        }
      }
      // DIRECTOR INFO REQUEST
      else if (this.job.service.name === ServiceEnum.EditDirector) {
        const componentRef = viewContainerRef.createComponent(
          UpdateDirectorInfoComponent,
        );

        componentRef.instance.job = this.job;
      } else if (this.job.service.name === ServiceEnum.RemoveDirector) {
        const componentRef = viewContainerRef.createComponent(
          RemoveDirectorInfoComponent,
        );

        componentRef.instance.job = this.job;
      } else if (this.job.service.name === ServiceEnum.ReplaceDirector) {
        const componentRef = viewContainerRef.createComponent(
          ReplaceDirectorInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
      // SHAREHOLDER
      else if (this.job.service.name === ServiceEnum.EditShareholder) {
        const componentRef = viewContainerRef.createComponent(
          UpdateShareholderInfoComponent,
        );

        componentRef.instance.job = this.job;
      } else if (
        this.job.service.name === ServiceEnum.IndividualTransferShare ||
        this.job.service.name === ServiceEnum.CorporateTransferShare
      ) {
        const componentRef = viewContainerRef.createComponent(
          TransferSharesInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
      // INCREASE CAPITAL REQUEST
      else if (this.job.service.name === ServiceEnum.IncreaseCapital) {
        const componentRef = viewContainerRef.createComponent(
          IncreaseCapitalInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
      // TAX RETURN REQUEST
      else if (this.job.service.name === ServiceEnum.TaxReturn) {
        const componentRef = viewContainerRef.createComponent(
          TaxReturnReviewInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
      // HOLD AGM
      else if (this.job.service.name === ServiceEnum.Agm) {
        const componentRef = viewContainerRef.createComponent(
          HoldAgmReviewInfoComponent,
        );

        componentRef.instance.holdAgmInput = this.job.detailObject.agmInput;
        componentRef.instance.companyInfo = this.job.company;
      }
      //SPECIAL REQUEST
      else if (
        this.job.service.name === ServiceEnum.SpecialTransferShares ||
        this.job.service.name === ServiceEnum.SpecialIncreaseCompanyCapital
      ) {
        const componentRef = viewContainerRef.createComponent(
          SpecialRequestInfoComponent,
        );
      }

      //CHANGE FYE DATE
      else if (this.job.service.name === ServiceEnum.ChangeFyeDate) {
        const componentRef = viewContainerRef.createComponent(
          ChangeFyeDateInfoComponent,
        );

        componentRef.instance.job = this.job;
      }

      //GST REGISTRATION
      else if (this.job.service.name === ServiceEnum.GstRegistration) {
        const componentRef = viewContainerRef.createComponent(
          GstRegistrationInfoComponent,
        );

        componentRef.instance.job = this.job;
      }

      // GST RETURN
      else if (this.job.service.name === ServiceEnum.GstReturn) {
        const componentRef = viewContainerRef.createComponent(
          GstReturnInfoComponent,
        );

        componentRef.instance.job = this.job;
      }
    }
  }

  public get additionalRequest() {
    return this.job.detailObject.jobServiceOrder.additionalServices;
  }
}

<ng-container *ngIf="activeUser$ | async as activeUser">
  <div class="officer-information-wrapper">
    <section class="information-container" *ngIf="officerInput && identificationTypes">
      <!--      INDIVIDUAL INFORMATION-->
      <ng-container *ngIf="officerInputHelper.isIndividual(officerInput)">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Position Held&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
              {{ officerHelper.renderOfficerRole(officerType) }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Name&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
              {{
              officerInputHelper.getOfficerName(
                officerInput.officerPerson || officerInput.officerCompany
              )
              }}
            </p>
          </div>
          <ng-container *ngIf="!officerInputHelper.isNominee(officerInput)">
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Identification Document&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{
                officerInputHelper.getIdentificationTypeName(
                  officerInput,
                  identificationTypes
                )
                }}
              </p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Identification No&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{ officerInputHelper.getIdentificationNumber(officerInput) }}
              </p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Expiry Date&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{
                (officerInputHelper.getIdentificationExpiryDate(officerInput)
                  | date) || "-"
                }}
              </p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Nationality&colon;
              </p>
              <span class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{ officerInputHelper.getCountryName(officerInput, countries) }}
              </span>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Email&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{
                officerInputHelper.getOfficerEmail(
                  officerInput.officerCompany || officerInput.officerPerson
                )
                }}
              </p>
            </div>
            <div class="!mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Phone&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
                {{ officerInputHelper.getPhone(officerInput) }}
              </p>
            </div>
            <div class="col-span-2 !mb-1 w-full flex flex-col">
              <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
                Address&colon;
              </p>
              <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray w-[75%]">
                {{ officerInputHelper.getAddress(officerInput, countries) }}
              </p>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!--      CORPORATE INFORMATION-->
      <ng-container *ngIf="officerInputHelper.isCorporate(officerInput)">
        <div class="grid grid-cols-2 gap-2 text-sub">
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Position Held&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
              {{ officerHelper.renderOfficerRole(officerType) }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Corporate Name&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 line-clamp-2 text-darkGray">
              {{
              officerInputHelper.getOfficerName(
                officerInput.officerPerson || officerInput.officerCompany
              )
              }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Country of Incorporation&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
              {{ officerInputHelper.getCountryName(officerInput, countries) }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Registration Number&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
              {{ officerInputHelper.getRegistrationNumber(officerInput) }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Incorporation Date&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
              {{
              (officerInputHelper.getRegistrationDate(officerInput) | date) ||
              "-"
              }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Email&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
              {{ officerInputHelper.getEmail(officerInput) }}
            </p>
          </div>
          <div class="!mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Phone&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray">
              {{ officerInputHelper.getPhone(officerInput) }}
            </p>
          </div>
          <div class="col-span-2 !mb-1 w-full flex flex-col">
            <p class="block font-['OpenSans_SemiBold'] font-semibold text-darkGray opacity-80 !mb-0">
              Address&colon;
            </p>
            <p class="mat-subheading-1 !text-sub !mb-0 text-darkGray w-[75%]">
              {{ officerInputHelper.getAddress(officerInput, countries) }}
            </p>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>

import { CountryType } from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';

export class Country extends BaseModel implements CountryType {
  id: string;
  phone: number;
  code: string;
  name: string;
  symbol: string;
  currency: string;

  constructor (state: CountryType) {
    super(state);
  }

  getModelName (): string {
    return Country.name;
  }
}

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { BusinessProfileService } from '@app/modules/account-settings/pages/business-profile/services/business-profile.service';
import { ACTIVE_USER } from '@app/modules/auth/providers/auth.provider';
import InAppNotificationLogService from '@app/modules/in-app-notification-log/services/in-app-notification-log.service';
import { JobDetailTab } from '@app/modules/job/pages/job-detail/job-detail.component';
import { User } from '@app/modules/user/models/user.model';
import { AppRoutes, APP_ROUTES } from '@config/app-routes.config';
import {
  InAppNotificationEnum,
  InAppNotificationLogType,
} from '@generated/graphql';
import { LetModule } from '@ngrx/component';
import Echo from 'laravel-echo';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    NgOptimizedImage,
    LetModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public unreadCount$ = new BehaviorSubject<number>(0);
  public notifications$ = new BehaviorSubject<InAppNotificationLogType[]>([]);
  public unreadNotifications$ = new BehaviorSubject<InAppNotificationLogType[]>(
    [],
  );
  public readNotifications$ = new BehaviorSubject<InAppNotificationLogType[]>(
    [],
  );

  private _logSub = new Subscription();

  public markingReadAll$ = new BehaviorSubject(false);

  constructor(
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    private _businessProfileService: BusinessProfileService,
    private _inAppNotificationLogService: InAppNotificationLogService,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this._businessProfileService.getBusinessProfile().subscribe((response) => {
      if (window['Echo']) {
        const echo = window['Echo'] as Echo;
        const channel = `in-app-noti.${response.id}`;

        this.activeUser$.subscribe((user) => {
          echo.leaveAllChannels();

          this.getLogs();

          echo.private(channel).listen('.noti-log.created', (e: any) => {
            if (e.log && e.log.userId === user.id) {
              const currentUnread = this.unreadNotifications$.getValue();
              const nextUnread = [e.log, ...currentUnread];

              this.unreadNotifications$.next(nextUnread);
              this.unreadCount$.next(nextUnread.length);

              this.combineNotifications();
            }
          });
        });
      }
    });
  }

  private getLogs() {
    this._logSub = this._inAppNotificationLogService
      .getInAppNotificationLogs()
      .subscribe((data) => {
        const unread = data.filter((d) => !d.isRead);

        this.unreadNotifications$.next(unread);
        this.unreadCount$.next(unread.length);
        this.readNotifications$.next(data.filter((d) => d.isRead));

        this.combineNotifications();
      });
  }

  public showDetail(log: InAppNotificationLogType) {
    if (log.isRead) {
      this.redirectWhenClickOnNoti(log);
    } else {
      this.updateReadStatusThenRedirect(log);
    }
  }

  public getNotificationIcon(log: InAppNotificationLogType): string {
    const prefixURL: string = 'assets/images/icons/';
    switch (log.type) {
      case InAppNotificationEnum.Conversation: {
        return prefixURL + 'noti-unread-message.svg';
      }
      case InAppNotificationEnum.JobRequest: {
        return prefixURL + 'noti-job-request.svg';
      }
      case InAppNotificationEnum.JobAssigned: {
        return prefixURL + 'noti-job-assigned.svg';
      }
      case InAppNotificationEnum.JobDone: {
        return prefixURL + 'noti-job-done.svg';
      }
      case InAppNotificationEnum.RegisterCompany: {
        return prefixURL + 'noti-register-company.svg';
      }
      case InAppNotificationEnum.TransferCompany: {
        return prefixURL + 'noti-transfer-company.svg';
      }
      case InAppNotificationEnum.PaymentReceived: {
        return prefixURL + 'noti-payment-receive.svg';
      }
      case InAppNotificationEnum.PaymentProcess: {
        return prefixURL + 'noti-payment-process.svg';
      }
      case InAppNotificationEnum.PaymentApproved: {
        return prefixURL + 'noti-payment-approved.svg';
      }
      case InAppNotificationEnum.PaymentRejected: {
        return prefixURL + 'noti-payment-rejected.svg';
      }
      default: {
        return prefixURL + 'noti-default.svg';
      }
    }
  }

  private updateReadStatusThenRedirect(log: InAppNotificationLogType) {
    this._inAppNotificationLogService
      .readInAppNotification(log.id)
      .subscribe(async (data) => {
        if (data) {
          this._logSub.unsubscribe();
          this.getLogs();
          // const currentUnread = this.unreadNotifications$.getValue();
          // this.unreadNotifications$.next(
          //   currentUnread.filter((u) => u.id !== log.id),
          // );
          //
          // const currentRead = this.readNotifications$.getValue();
          // this.readNotifications$.next([log, ...currentRead]);
          //
          // this.unreadCount$.next(this.unreadCount$.getValue() - 1);

          this.combineNotifications();

          this.redirectWhenClickOnNoti(log);
        }
      });
  }

  private async redirectWhenClickOnNoti(log: InAppNotificationLogType) {
    switch (log.type) {
      case InAppNotificationEnum.CompanyProfileCreated:
        await this._router.navigate(this.appRoutes.company(log.entityId));
        break;
      case InAppNotificationEnum.ExtraJobCreated:
        const details = JSON.parse(log.details);

        await this._router.navigate(this.appRoutes.jobDetail(details.jobId), {
          queryParams: {
            tab: JobDetailTab.ExtraJob,
          },
        });
        break;

      default:
        await this._router.navigate(this.appRoutes.jobDetail(log.entityId));
        break;
    }
  }

  private combineNotifications(): void {
    this.notifications$.next([
      ...this.unreadNotifications$.getValue(),
      ...this.readNotifications$.getValue(),
    ]);
  }

  public markReadAll(e: MouseEvent): void {
    e.stopPropagation();

    this.markingReadAll$.next(true);

    this._inAppNotificationLogService
      .markReadAllInAppNotificationLog()
      .subscribe({
        next: (result) => {
          if (result) {
            this._logSub.unsubscribe();
            this.getLogs();
            this.combineNotifications();
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.markingReadAll$.next(false);
        },
      });
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {basicOptions} from "@modules/company/models/types/company-corporate-service-option.const";
import {catchError, EMPTY, finalize, Observable, switchMap, tap} from "rxjs";
import {BusinessProfile} from "@modules/account-settings/pages/business-profile/models/business-profile.model";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {User} from "@modules/user/models/user.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormFactory} from "@shared/factories/form.factory";
import {GstReturnApi} from "@modules/gst-return/api/gst-return.api";
import {CompanyService} from "@modules/company/services/company.service";
import {SnackBarService} from "@shared/services/snack-bar.service";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {CommonHelper} from "@shared/helpers/common.helper";
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Validators} from "@angular/forms";
import {DateTime} from "luxon";
import {FileTypeEnum, UploadCompanyGstReturnInput} from "@generated/graphql";
import {DropzoneFileChangedEvent} from "@shared/component/common/dropzone-upload/dropzone-upload.component";
import {startCase} from 'lodash-es';
import {CompanyGstReturnItem} from "@modules/company/models/company-gst-return-item.model";
import {startWith} from "rxjs/operators";
import FileService from "@modules/file/services/file.service";

export type GstReturnSubmitDialogData = {
  companyGstReturnItem?: CompanyGstReturnItem
}

@UntilDestroy()
@Component({
  selector: 'app-gst-return-submit-dialog',
  templateUrl: './gst-return-submit-dialog.component.html',
  styleUrls: ['./gst-return-submit-dialog.component.scss']
})
export class GstReturnSubmitDialogComponent implements OnInit {
  startCase = startCase
  readonly form = this.formFactory.gstReturnForm()
  protected readonly options = basicOptions;

  get formValue() {
    return this.form.getRawValue()
  }

  public get businessProfile$(): Observable<BusinessProfile> {
    return this.businessProfileService.activeEntity$;
  }

  constructor(
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    @Inject(MAT_DIALOG_DATA) public readonly data: GstReturnSubmitDialogData,
    private readonly formFactory: FormFactory,
    private readonly dialogRef: MatDialogRef<GstReturnSubmitDialogComponent>,
    private readonly gstReturnApi: GstReturnApi,
    private readonly companyService: CompanyService,
    private readonly snackBarService: SnackBarService,
    private readonly screenSpinnerService: ScreenSpinnerService,
    protected readonly commonHelper: CommonHelper,
    private readonly businessProfileService: BusinessProfileService,
    private readonly fileService: FileService
  ) {
  }

  ngOnInit(): void {
    const form = this.form.controls.gstReturnDetail

    const controls = form.controls

    const {companyGstReturnItem} = this.data

    if (companyGstReturnItem) {
      const {quarter, year} = companyGstReturnItem

      form.patchValue({
        quarter,
        year
      })
    }

    this.companyService.activeEntity$.pipe(
      untilDestroyed(this),
      tap(company => {
        this.form.patchValue({
          companyId: company.id
        })

        this.form.controls.jobId.removeValidators(Validators.required)

        this.form.updateValueAndValidity()
      })
    ).subscribe()

    this.form.valueChanges.pipe(
      untilDestroyed(this),
      startWith(this.formValue),
      tap(() => {
        const requestedFileControl = controls.requestedFile

        if (this.formValue.gstReturnDetail.needToPrepareDocument) {
          requestedFileControl.addValidators(Validators.required)
        } else {
          requestedFileControl.removeValidators(Validators.required)
          requestedFileControl.setValue(null, { emitEvent: false })
        }

        this.form.updateValueAndValidity({ emitEvent: false })
      }),
    ).subscribe()
  }

  submit() {
    this.screenSpinnerService.setLoading(true)

    this.gstReturnApi.uploadCompanyGstReturn$({
      ...this.formValue,
      effectiveDate: DateTime.fromJSDate(this.formValue.effectiveDate).toISODate()
    } as UploadCompanyGstReturnInput)
      .pipe(
        switchMap(result => {
          if (result) {
            this.dialogRef.close()

            return this.companyService.refreshActiveEntity$(this.formValue.companyId).pipe(
              switchMap(company => this.fileService.getCompanyDocuments(company.id)),
              finalize(() => {
                this.screenSpinnerService.setLoading(false)
                this.snackBarService.pushSuccessAlert('Request success!')
              })
            )
          }

          this.screenSpinnerService.setLoading(false)

          this.snackBarService.pushErrorMessage()

          return EMPTY
        }),
        catchError(e => {
          console.error(e)

          this.screenSpinnerService.setLoading(false)

          this.snackBarService.pushErrorMessage()

          return EMPTY
        })
      ).subscribe()
  }

  filesChanged($event: DropzoneFileChangedEvent) {
    this.form.controls.gstReturnDetail.controls.file.setValue($event.files.length
      ? $event.files[0]
      : null
    )
  }

  protected readonly FileTypeEnum = FileTypeEnum;

  requestedFileChanged($event: DropzoneFileChangedEvent) {
    this.form.controls.gstReturnDetail.controls.requestedFile.setValue(
      $event.files.length
        ? $event.files[0]
        : null
    )
  }
}

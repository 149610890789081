import { Injectable } from '@angular/core';
import {
  CompaniesQuery,
  CompanyBasicInfoInput,
  CompanyQuery,
  CompanySortKeyInput,
  CompanyType,
  CreateCompanyProfileMutation,
  SubmitCompanyKycMutation,
  ConfirmCompanyKycMutation,
  PaginationInput,
  ChangeFyeDateMutation,
  ChangeFyeDateInput,
  RegisterGstInput,
  RegisterGstMutation,
  DownloadCompanyDocumentsMutation,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';
import { paginationDataFactory } from '@shared/factories/pagination.factoy';
import { companyFactory } from '@modules/company/factories/company.factory';
import { PaginationData } from '@shared/types/pagination.type';
import { Company } from '@modules/company/models/company.model';
import { FindContract } from '@shared/contracts/find.contract';

@Injectable({
	providedIn: 'root',
})
export class CompanyApi implements FindContract<Company> {
	constructor(
		protected readonly companiesQuery: CompaniesQuery,
		private readonly companyQuery: CompanyQuery,
		private readonly createCompanyProfileMutation: CreateCompanyProfileMutation,
		private readonly submitCompanyKycMutation: SubmitCompanyKycMutation,
		private readonly confirmCompanyKycMutation: ConfirmCompanyKycMutation,
		private readonly changeFyeDateMutation: ChangeFyeDateMutation,
		private readonly registerGstMutation: RegisterGstMutation,
		private readonly downloadCompanyDocumentsMutation: DownloadCompanyDocumentsMutation,
	) {}

	find$(id: string): Observable<Company> {
		return this.companyQuery
			.fetch({ id }, { fetchPolicy: 'no-cache' })
			.pipe(
				map((res) =>
					res.data.company
						? companyFactory.create(res.data.company as CompanyType)
						: null,
				),
			);
	}

	getCompanies$(
		pagination: PaginationInput,
		searchText: string = '',
		sorts: CompanySortKeyInput[] = [],
	): Observable<PaginationData<Company>> {
		return this.companiesQuery
			.fetch({ pagination, searchText, sorts }, { fetchPolicy: 'no-cache' })
			.pipe(
				map((res) =>
					res.data.companies
						? paginationDataFactory.create(res.data.companies, companyFactory)
						: null,
				),
			);
	}

  submitKyc$ (id: Company['id']): Observable<Company> {
    return this.submitCompanyKycMutation.mutate({id}).pipe(
      map(res => companyFactory.tryCreating(res.data.submitCompanyKyc as CompanyType))
    )
  }

  confirmKyc$ (id: Company['id']): Observable<Company> {
    return this.confirmCompanyKycMutation.mutate({id}).pipe(
      map(res => companyFactory.tryCreating(res.data.confirmCompanyKyc as CompanyType))
    )
  }

  changeFYEDate$ (input: ChangeFyeDateInput): Observable<Company> {
    return this.changeFyeDateMutation.mutate({input}).pipe(
      map(res => companyFactory.tryCreating(res.data.changeFYEDate as CompanyType))
    )
  }

  registerGst$ (input: RegisterGstInput): Observable<Company> {
    return this.registerGstMutation.mutate({input}).pipe(
      map(res => companyFactory.tryCreating(res.data.registerGst as CompanyType))
    )
  }

  downloadCompanyDocuments$ (id: Company['id']): Observable<string> {
    return this.downloadCompanyDocumentsMutation.mutate({id}).pipe(
      map(res => res.data.downloadCompanyDocuments)
    )
  }

	createCompanyProfile(id: string, input: CompanyBasicInfoInput) {
		return this.createCompanyProfileMutation
			.mutate({
				id,
				input,
			})
			.pipe(map((result) => result.data.createCompanyProfile));
	}
}

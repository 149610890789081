import { FormArray, FormGroup, Validators } from '@angular/forms';
import { DirectorFormType } from '@app/modules/job/models/form/director-form-type';
import { ShareholderFormType } from '@app/modules/job/models/form/shareholder-form-type';
import { OfficerIndividualFormType } from '@app/modules/officer/models/form/officer-individual-form-type';
import { CompanyDirectorInput, OfficerTypeEnum } from '@generated/graphql';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { OfficerCorporateForm } from '@shared/classes/officer/officer-corporate-form.class';
import { OfficerIndividualForm } from '@shared/classes/officer/officer-individual-form.class';
import { CompanyOfficerForm } from './company-officer-form.class';
import { JobOrderServiceManagement } from './job-order-service-management.class';

export class CompanyDirectorForm extends CompanyOfficerForm {
  protected _form: FormArray<FormGroup<DirectorFormType>>;

  useNominee = false;

  valid = false;

  needLocal = false;

  needIndividual = false;

  constructor(private _orderServiceManagement: JobOrderServiceManagement) {
    super();

    this._form = this.fb.array<FormGroup<DirectorFormType>>(
      [],
      Validators.required,
    );
  }

  addDirector(officerForm: AbstractOfficerForm, refId: string): void {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    officerForm.officerForm.enable();

    let id = `temporary-${Date.now()}`;

    if (refId && !refId.includes('temporary-')) {
      id = refId;
    }

    const newControl = this.fb.group<DirectorFormType>({
      id: this.fb.control(id),
      officerType: this.fb.control(officerType),
      isNominee: this.fb.control(false),
      dateFrom: this.fb.control(new Date()),
      [officerName]: officerForm.officerForm,
      refId: this.fb.control(refId),
    });

    this._form.push(newControl);
  }

  updateDirector(
    index: number,
    officerForm: AbstractOfficerForm,
    refId: string,
  ) {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    const currentControl = this._form.at(index);

    if (currentControl.controls.officerType.value === officerType) {
      currentControl.patchValue({
        officerType: officerType,
        isNominee: false,
        [officerName]: officerForm.officerForm.getRawValue(),
        refId: refId,
      });
    } else {
      const newInput = this.fb.group<DirectorFormType>({
        id: this.fb.control(currentControl.controls.id.value),
        officerType: this.fb.control(officerType),
        isNominee: this.fb.control(false),
        [officerName]: officerForm.officerForm.getRawValue(),
        refId: this.fb.control(refId),
        dateFrom: this.fb.control(new Date()),
      });

      this._form.setControl(index, newInput);
    }
  }

  updateDirectorByRef(
    shareholderControl: FormGroup<ShareholderFormType>,
    officerForm: AbstractOfficerForm,
  ) {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    this._form.controls.forEach((c, idx) => {
      if (
        c.controls.refId &&
        c.controls.refId.value === shareholderControl.controls.id.value
      ) {
        if (shareholderControl.controls.officerType.value === officerType) {
          c.patchValue({
            [officerName]: officerForm.officerForm.getRawValue(),
          });
        } else {
          const newInput = this.fb.group({
            id: c.controls.id.value,
            officerType: [officerType],
            isNominee: [false],
            [officerName]: officerForm.officerForm.getRawValue(),
            refId: c.controls.refId.value,
            dateFrom: [new Date()],
          }) as unknown as FormGroup<DirectorFormType>;

          this._form.setControl(idx, newInput);
        }
      } else if (
        c.controls.refId &&
        shareholderControl.controls.officerType.value ===
        OfficerTypeEnum.Corporate &&
        c.controls.refId.value ===
        shareholderControl.controls.officerCompany.controls.representative
          .controls.id.value
      ) {
        c.patchValue({
          officerPerson:
            officerForm.officerForm.controls.representative.getRawValue(),
        });
      }
    });
  }

  addNominee() {
    if (this.useNominee === true) return;

    this._form.push(
      this.fb.group<DirectorFormType>({
        officerType: this.fb.control(OfficerTypeEnum.Individual),
        isNominee: this.fb.control(true),
        dateFrom: this.fb.control(new Date()),
        officerPerson: this.fb.group<OfficerIndividualFormType>({
          firstName: this.fb.control('Nominee'),
          countryId: this.fb.control(this._countryOfIncorporation.country.id),
        }),
      }),
    );

    this.useNominee = true;
    this._orderServiceManagement.toggleUserNomineeService('add');
  }

  removeNominee() {
    if (this.useNominee === false) return;

    const index = this._form.value.findIndex((d) => d.isNominee === true);

    this._form.removeAt(index);

    this.useNominee = false;

    this._orderServiceManagement.toggleUserNomineeService('remove');
  }

  checkValid() {
    this.valid = this.form.valid;

    if (this._countryOfIncorporation.country.code.toUpperCase() !== 'SG') {
      this.needLocal = false;

      // For Hongkong
      const validDirectors = this.form
        .getRawValue()
        .filter((d) => !!d.officerPerson);

      if (validDirectors.length > 0) {
        this.needIndividual = false;
      } else {
        this.needIndividual = true;
      }
    } else {
      // For Singapore
      const validDirectors = this.form
        .getRawValue()
        .filter(
          (d) =>
            d.isNominee ||
            d.officerPerson.countryId ===
            this._countryOfIncorporation.country.id,
        );

      if (validDirectors.length > 0) {
        this.needLocal = false;
      } else {
        this.needLocal = true;
      }
    }
  }

  setDataFromInput(input: CompanyDirectorInput[]) {
    input.forEach((data) => {
      if (data.isNominee) {
        this._form.push(
          this.fb.group<DirectorFormType>({
            officerType: this.fb.control(OfficerTypeEnum.Individual),
            isNominee: this.fb.control(true),
            dateFrom: this.fb.control(new Date()),
            officerPerson: this.fb.group<OfficerIndividualFormType>({
              firstName: this.fb.control('Nominee'),
              countryId: this.fb.control(
                this._countryOfIncorporation.country.id,
              ),
            }),
          }),
        );
      } else {
        let officerType = OfficerTypeEnum.Individual;
        let officerName = 'officerPerson';
        let officerForm: OfficerIndividualForm | OfficerCorporateForm;

        if (data.officerCompany) {
          officerType = OfficerTypeEnum.Corporate;
          officerName = 'officerCompany';

          officerForm = new OfficerCorporateForm();
          officerForm.createOfficerForm();
          officerForm.setEditData(data.officerCompany);
        } else {
          officerForm = new OfficerIndividualForm();
          officerForm.createOfficerForm();
          officerForm.setEditData(data.officerPerson);
        }

        this._form.push(
          this.fb.group<DirectorFormType>({
            officerType: this.fb.control(officerType),
            isNominee: this.fb.control(false),
            dateFrom: this.fb.control(new Date()),
            [officerName]: officerForm.officerForm,
          }),
        );
      }
    });
  }
}

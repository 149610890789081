import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SalutationPipe} from 'src/shared/share-pipes/pipes/salutation.pipe';
import {PhonePipe} from './pipes/phone.pipe';
import {RolePipe} from './pipes/role.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {FileSizePipe} from './pipes/filesize.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    SalutationPipe,
    PhonePipe,
    RolePipe,
    DurationPipe,
    FileSizePipe,
    SafeHtmlPipe,
  ],
  imports: [CommonModule],
  exports: [SalutationPipe, PhonePipe, RolePipe, DurationPipe, FileSizePipe, SafeHtmlPipe],
})
export class SharePipesModule {
}

import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ServiceSupportedCountry} from '@modules/service/models/service-supported-country.model';
import {
  ServiceSupportedCountryFactory,
  serviceSupportedCountryFactory
} from '@modules/service/factories/service-supported-country.factory';
import {ServiceCategory} from '@modules/service/models/service-category.model';
import {EntityService} from '@shared/services/entity.service';
import {ServiceEnum, ServiceSupportedCountryType} from '@generated/graphql';
import {
  SERVICE_SUPPORTED_COUNTRY_STORE,
  ServiceSupportedCountryStore
} from '@modules/service/store/service-supported-country.store';
import {EntityFeatureNameEnum} from '@shared/contracts/entity-store.contract';
import {Country} from '@modules/country/models/country.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceSupportedCountryService extends EntityService<EntityFeatureNameEnum.ServiceSupportedCountry, ServiceSupportedCountryType, ServiceSupportedCountry> {
  get factory(): ServiceSupportedCountryFactory {
    return serviceSupportedCountryFactory;
  }

  constructor(
    protected readonly store: Store,
    @Inject(SERVICE_SUPPORTED_COUNTRY_STORE) protected readonly entityStore: ServiceSupportedCountryStore
  ) {
    super(store, entityStore);
  }

  getByServiceCategoryId$(id: ServiceCategory['id']): Observable<ServiceSupportedCountry[]> {
    return this.store.select(this.entityStore.selectByServiceCategoryId(id)).pipe(
      map(serviceSupportedCountries => serviceSupportedCountries.map(serviceSupportedCountry => this.factory.create(serviceSupportedCountry))),
    );
  }

  getByCountryId$(id: Country['id']): Observable<ServiceSupportedCountry[]> {
    return this.entities$.pipe(
      map(entities => entities.filter(item => item.supportedCountry.country.id === id))
    );
  }

  getActiveServicesByCountryId$(id: Country['id']): Observable<ServiceSupportedCountry[]> {
    return this.getByCountryId$(id).pipe(
      map(entities => entities.filter(item => item.isActive && !item.isArchive))
    );
  }

  getActiveAdditionalServicesByCountryId$(id: Country['id']): Observable<ServiceSupportedCountry[]> {
    return this.getActiveServicesByCountryId$(id).pipe(
      map(entities => entities.filter(item => item.service.serviceCategory.isAdditionalService()))
    );
  }

  findByCountryIdAndServiceName$(id: Country['id'], name: ServiceEnum): Observable<ServiceSupportedCountry> {
    return this.getActiveServicesByCountryId$(
      id
    ).pipe(
      map(value => value.find(item => item.service.name === name))
    )
  }

  getIncorporationServiceByCountryId$(id: Country['id']): Observable<ServiceSupportedCountry[]> {
    return this.getActiveServicesByCountryId$(id).pipe(
      map(entities => entities.filter(item => item.service.serviceCategory.isIncorporationService()))
    );
  }

  /**
   * @deprecated Please use visiblePrice method in BusinessPlanService instead.
   */
  // visiblePrice (paymentType: PaymentTypeName, visiblePayment: boolean = false): boolean {
  //   return !PaymentType.isContactToClient(paymentType as PaymentTypeName) && visiblePayment;
  // }
}

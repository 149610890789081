import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OfficerType, OfficerTypeEnum } from '@generated/graphql';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupportedCountry } from '@modules/supported-country/models/supported-country.model';
import { SupportedCountryService } from '@modules/supported-country/services/supported-country.service';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { User } from '@modules/user/models/user.model';
import { USE_DETAIL_OF } from '@modules/officer/models/types/officer-selection.const';
import { UseDetailOfEnum } from '@shared/types/commonEnum';
import { BusinessPlan } from '@modules/account-settings/pages/business-profile/models/business-plan.model';
import { BusinessPlanService } from '@modules/account-settings/pages/business-profile/services/business-plan.service';
import { startCase } from 'lodash-es';
import { CommonHelper } from '@shared/helpers/common.helper';
import { Company } from '@modules/company/models/company.model';
import { CompanyDirector } from '@modules/company/models/company-director.model';
import { CompanyShareholder } from '@modules/company/models/company-shareholder.model';
import { OfficerRequestFormManagement } from './classes/officer-request-form-management';
import { DirectorOfficerRequestForm } from './classes/director-officer-request-form.class';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { ServiceSupportedCountryService } from '@app/modules/service/services/service-supported-country.service';
import { AppRoutes, APP_ROUTES } from '@config/app-routes.config';
import { Router } from '@angular/router';
import { ExistsOfficerType } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/classes/exists-officer.type';
import { MatRadioChange } from '@angular/material/radio';
import { OfficerService } from '@app/modules/officer/services/officer.service';
import { LookupOfficerDialogComponent } from '@shared/component/job/component/company-incorporation/dialog/lookup-officer-dialog/lookup-officer-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { ShareholderOfficerRequestForm } from './classes/shareholder-officer-request-form.class';
import { IndividualInfoComponent } from '@shared/component/company/common/individual-info/individual-info.component';
import { CorporateInfoComponent } from '@shared/component/company/common/corporate-info/corporate-info.component';
import { ExistsOfficersSelectComponent } from '@shared/component/officer/exists-officers-select/exists-officers-select.component';

type DialogData = {
  companyProfile: Company;
  officerRole: OfficerRoleEnum;
  selectedOfficer: CompanyDirector | CompanyShareholder;
};

@UntilDestroy()
@Component({
  selector: 'app-officer-resolution-request-dialog',
  templateUrl: './officer-resolution-request-dialog.component.html',
  styleUrls: ['./officer-resolution-request-dialog.component.scss'],
})
export class OfficerResolutionRequestDialogComponent
  implements OnInit, OnDestroy {
  protected readonly OfficerTypeEnum = OfficerTypeEnum;

  protected readonly useDetailOfOption = USE_DETAIL_OF(
    OfficerRoleEnum.DIRECTOR,
  );

  protected readonly startCase = startCase;

  public useDetailOfSelected: UseDetailOfEnum = UseDetailOfEnum.New;

  public typeSelected: OfficerTypeEnum = OfficerTypeEnum.Individual;

  public isOnReview: boolean = false;

  private _role: OfficerRoleEnum;

  private _companyProfile: Company;

  public officerRequestFormManagement: OfficerRequestFormManagement;

  public get businessPlan$(): Observable<BusinessPlan> {
    return this.businessPlanService.businessPlan$;
  }

  public get supportedCountry$(): Observable<SupportedCountry> {
    return this.supportedCountryService.findByCountryId$(
      this.companyProfile.country.id,
    );
  }

  public get companyProfile(): Company {
    return this._companyProfile;
  }

  private _selectedOfficer: CompanyDirector | CompanyShareholder;
  public get selectedOfficer(): CompanyDirector | CompanyShareholder {
    return this._selectedOfficer;
  }

  public submitting$ = new BehaviorSubject(false);

  public supportedCountry: SupportedCountry;

  @ViewChild(IndividualInfoComponent)
  officerPersonForm: IndividualInfoComponent;

  @ViewChild(CorporateInfoComponent) officerCompanyForm: CorporateInfoComponent;

  @ViewChild(ExistsOfficersSelectComponent)
  existsOfficersSelectComponent: ExistsOfficersSelectComponent;

  constructor(
    @Inject(APP_ROUTES) protected readonly appRoutes: AppRoutes,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly dialogRef: MatDialogRef<OfficerResolutionRequestDialogComponent>,
    private readonly businessPlanService: BusinessPlanService,
    protected readonly supportedCountryService: SupportedCountryService,
    protected readonly commonHelper: CommonHelper,
    private _snackbarService: SnackBarService,
    private _serviceSupportedCountryService: ServiceSupportedCountryService,
    private readonly _router: Router,
    private _officerService: OfficerService,
    private _dialog: MatDialog,
    private _officerInputHelper: OfficerInputHelpers,
  ) {
    this.initData();
  }

  ngOnInit(): void {
    this.setUpDialog();
  }

  private setUpDialog() {
    this.activeUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.officerRequestFormManagement.activeUser = user;

      this.officerRequestFormManagement.company = this.data.companyProfile;

      this.officerRequestFormManagement.typeSelected = this.typeSelected;

      this.supportedCountry$
        .pipe(untilDestroyed(this))
        .subscribe((supportedCountry) => {
          this.supportedCountry = supportedCountry;

          this.officerRequestFormManagement.countryOfIncorporation =
            this.supportedCountry;

          this._serviceSupportedCountryService
            .getByCountryId$(supportedCountry.country.id)
            .pipe(untilDestroyed(this))
            .subscribe((services) => {
              this.officerRequestFormManagement.jobServiceOrder.incorporationServices =
                services.filter(
                  (s) => s.supportedCountry.id === supportedCountry.id,
                );

              this.officerRequestFormManagement.setServiceSupportedCountry(
                services,
              );
            });
        });

      if (this.isEditMode()) {
        if (
          this.selectedOfficer.officer.officerType ===
          OfficerTypeEnum.Individual
        ) {
          this.typeSelected = OfficerTypeEnum.Individual;
        } else if (
          this.selectedOfficer.officer.officerType === OfficerTypeEnum.Corporate
        ) {
          this.typeSelected = OfficerTypeEnum.Corporate;
        }

        this.officerRequestFormManagement.typeSelected = this.typeSelected;

        this.officerRequestFormManagement.createForm();

        this.officerRequestFormManagement.setEditData(this.selectedOfficer);
      } else {
        this.officerRequestFormManagement.createForm();

        this.officerRequestFormManagement.mode = 'New';
      }

      if (this.isDirector()) {
        this.officerRequestFormManagement.existsOfficerManagement.existType =
          'Director';

        this.officerRequestFormManagement.existsOfficerManagement.setExceptOfficerIds(
          this.data.companyProfile,
        );

        this.officerRequestFormManagement.existsOfficerManagement.setDataFromServer(
          this.companyProfile.shareholders.map((s) => s.officer),
        );

        this.officerRequestFormManagement.filterAndSortExistOfficers();

        if (user.isCustomer()) {
          this._officerService
            .allOfficersByCustomer(this.supportedCountry.country.code)
            .pipe(untilDestroyed(this))
            .subscribe((officers) => {
              this.officerRequestFormManagement.existsOfficerManagement.setDataFromServer(
                officers,
              );

              this.officerRequestFormManagement.filterAndSortExistOfficers();
            });
        }
      } else if (this.isShareholder()) {
        this.officerRequestFormManagement.existsOfficerManagement.existType =
          'Shareholder';
      }
    });
  }

  //PUBLIC FEATURES
  public isEditMode() {
    return !!this.selectedOfficer;
  }

  public isDirector(): boolean {
    return this._role === OfficerRoleEnum.DIRECTOR;
  }

  public isNominee(): boolean {
    return this.typeSelected === OfficerTypeEnum.Nominee;
  }

  public showExistingOfficer(): boolean {
    return this.useDetailOfSelected === UseDetailOfEnum.Existing;
  }

  public isShareholder(): boolean {
    return this._role === OfficerRoleEnum.SHAREHOLDER;
  }

  public disableInputForm(): boolean {
    return this.useDetailOfSelected === UseDetailOfEnum.Existing;
  }

  public onCloseDialog(): void {
    this.dialogRef.close();
  }

  hideCorporateSelect(): boolean {
    const excepts = ['SG'];

    return (
      this.data.officerRole === OfficerRoleEnum.DIRECTOR &&
      excepts.includes(this.supportedCountry.country.code.toUpperCase())
    );
  }

  public onSelectType({ value }) {
    this.typeSelected = value;

    this.officerRequestFormManagement.typeSelected = this.typeSelected;

    this.officerRequestFormManagement.createForm();
  }

  public useDetailChanged({ value }: MatRadioChange) {
    if (value === UseDetailOfEnum.New.toString()) {
      this.useNewOfficerForm();
    }
  }

  private useNewOfficerForm() {
    this.officerRequestFormManagement.createForm();

    setTimeout(() => {
      this.officerRequestFormManagement.formInput.officerForm.enable();

      this.officerRequestFormManagement.formInput.officerForm.reset();

      this.officerRequestFormManagement.existsOfficerManagement.refId =
        undefined;
    }, 468);
  }

  public onReviewRequest(): void {
    this.isOnReview = true;
  }

  public onBackToRequestInPut(): void {
    this.isOnReview = false;
  }

  public onSubmitRequest() {
    this.officerRequestFormManagement.submitForm();

    this.officerRequestFormManagement.postStatus$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status === 'SUCCESS') {
          if (this.officerRequestFormManagement.createdJob) {
            if (this.officerRequestFormManagement.mode === 'New') {
              this._snackbarService.pushAlert(
                'Add new officer request has been submitted!',
              );
            } else if (this.officerRequestFormManagement.mode === 'Edit') {
              this._snackbarService.pushAlert(
                'Edit officer request has been submitted!',
              );
            }

            const createdJob = this.officerRequestFormManagement.createdJob;

            this.businessPlanService
              .getByUser$(this.activeUser$)
              .pipe(untilDestroyed(this))
              .subscribe((plan) => {
                if (plan.isPremium()) {
                  this._router.navigate(
                    this.appRoutes.jobCheckout(createdJob.id),
                  );
                } else {
                  this._router.navigate(
                    this.appRoutes.jobDetail(createdJob.id),
                  );
                }
              });

            setTimeout(() => {
              this.dialogRef.close();
            }, 256);
          } else {
            if (this.officerRequestFormManagement.mode === 'New') {
              this._snackbarService.pushAlert('Add new officer has been submitted!');
            } else if (this.officerRequestFormManagement.mode === 'Edit') {
              this._snackbarService.pushAlert('Update officer has been submitted!');
            }

            setTimeout(() => {
              this.dialogRef.close(true);
            }, 256);
          }
        } else if (status === 'ERROR') {
          this._snackbarService.pushErrorMessage();
        }
      });
  }

  private initData() {
    this._role = this.data.officerRole;
    this._selectedOfficer = this.data.selectedOfficer;
    this._companyProfile = this.data.companyProfile;

    if (this._role === OfficerRoleEnum.DIRECTOR) {
      this.officerRequestFormManagement = inject(DirectorOfficerRequestForm);
    } else if (this._role === OfficerRoleEnum.SHAREHOLDER) {
      this.officerRequestFormManagement = inject(ShareholderOfficerRequestForm);
    }

    if (this.data.selectedOfficer) {
      this._selectedOfficer = this.data.selectedOfficer;
    }

    this.officerRequestFormManagement.role = this._role;

    if (this.isEditMode()) {
      this.officerRequestFormManagement.mode = 'Edit';
    } else {
      this.officerRequestFormManagement.mode = 'New';
    }
  }

  public getFormType(): string {
    if (this.isShareholder()) {
      return 'CompanyShareholderType';
    } else if (this.isDirector()) {
      return 'CompanyDirectorType';
    }

    return null;
  }

  existsOfficerSelectedHandler(existsOfficer: ExistsOfficerType): void {
    this.officerRequestFormManagement.existsOfficerManagement.refId =
      existsOfficer.id;

    this.typeSelected = existsOfficer.type;

    this.officerRequestFormManagement.typeSelected = this.typeSelected;

    this.officerRequestFormManagement.existsOfficerSelectedHandler(
      existsOfficer,
    );
  }

  resetForm(): void {
    this.useDetailOfSelected = UseDetailOfEnum.New;

    this.officerRequestFormManagement.resetRequestForm();

    if (this.isEditMode()) {
      this.officerRequestFormManagement.setEditData(this.selectedOfficer);
    } else {
      if (this.officerPersonForm) {
        this.officerPersonForm.resetForm();
      }

      if (this.officerCompanyForm) {
        this.officerCompanyForm.resetForm();
      }
    }
  }

  onOpenLookupOfficer() {
    const lookupOfficerDialog = this._dialog.open(
      LookupOfficerDialogComponent,
      {
        width: '50%',
        data: {
          countryOfIncorporation: this.supportedCountry,
          hideCorporate: this.hideCorporateSelect(),
          exceptOfficerIds:
            this.officerRequestFormManagement.existsOfficerManagement.exceptIds,
        },
      },
    );

    lookupOfficerDialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: { officer: OfficerType; type: OfficerTypeEnum }) => {
        if (result) {
          const { officer: foundOfficer, type } = result;

          this.officerRequestFormManagement.existsOfficerManagement.refId =
            foundOfficer.id;

          this.typeSelected = type;

          this.officerRequestFormManagement.typeSelected = this.typeSelected;

          this.officerRequestFormManagement.lookUpOfficerSelectedHandler(
            foundOfficer,
          );
        }
      });
  }

  getOldOfficerInputValue() {
    if (this.data.officerRole === OfficerRoleEnum.DIRECTOR) {
      return this._officerInputHelper.transformDirectorModelToInput(
        this.selectedOfficer as CompanyDirector,
      );
    } else if (this.data.officerRole === OfficerRoleEnum.SHAREHOLDER) {
      return this._officerInputHelper.transformShareholderModelToInput(
        this.selectedOfficer as CompanyShareholder,
      );
    }

    return null;
  }

  public disableOfficerTypeSelect(): boolean {
    return (
      this.useDetailOfSelected === UseDetailOfEnum.Existing ||
      this.isEditMode() ||
      this.officerRequestFormManagement.formInput.officerForm.disabled
    );
  }

  ngOnDestroy(): void {
    this.officerRequestFormManagement = undefined;
  }
}

import {Injectable} from "@angular/core";
import {FormBuilder, FormGroup, NonNullableFormBuilder} from "@angular/forms";
import {Validators} from "@config/validators.config";
import {
  CompanyIndirectCompanyShareholderForm,
  CompanyIndirectIndividualShareholderForm,
  CompanyIndirectShareholderBaseForm,
  OfficerIndirectCompanyForm,
  OfficerIndirectPersonForm
} from "@shared/factories/types/form.type";
import {GstRegistrationOptionsEnum, QuarterEnum} from "@generated/graphql";
import {GstRegistrationDateValidator} from "@utils/validation/app.validation";
import {Company} from "@modules/company/models/company.model";

@Injectable({
  providedIn: 'root'
})
export class FormFactory {
  constructor(
    private readonly fb: FormBuilder,
    private readonly nonNullableFb: NonNullableFormBuilder
  ) {
  }

  companyIndirectShareholderBaseForm(): FormGroup<CompanyIndirectShareholderBaseForm> {
    return this.nonNullableFb.group({
      companyId: this.nonNullableFb.control('', [Validators.required]),
      companyType: this.nonNullableFb.control('', [Validators.required]),
      percentageOfShares: this.nonNullableFb.control(0, [Validators.biggerThan(0), Validators.max(100)]),
    })
  }

  companyIndirectCompanyShareholderForm(): FormGroup<CompanyIndirectCompanyShareholderForm> {
    const {controls} = this.companyIndirectShareholderBaseForm()
    return this.nonNullableFb.group({
      ...controls,
      officerProfile: this.officerIndirectCompanyForm(),
    })
  }

  companyIndirectIndividualShareholderForm(): FormGroup<CompanyIndirectIndividualShareholderForm> {
    const {controls} = this.companyIndirectShareholderBaseForm()
    return this.nonNullableFb.group({
      ...controls,
      officerProfile: this.officerIndirectPersonForm(),
    })
  }

  officerIndirectPersonForm(): FormGroup<OfficerIndirectPersonForm> {
    return this.nonNullableFb.group({
      firstName: this.nonNullableFb.control('', [Validators.required]),
      lastName: this.nonNullableFb.control('', [Validators.required]),
      nationalityId: this.nonNullableFb.control('', [Validators.required]),
      countryId: this.nonNullableFb.control('', [Validators.required])
    })
  }

  officerIndirectCompanyForm(): FormGroup<OfficerIndirectCompanyForm> {
    return this.nonNullableFb.group({
      name: this.nonNullableFb.control('', [Validators.required]),
      countryId: this.nonNullableFb.control('', [Validators.required])
    })
  }

  registerGstForm(company: Company) {
    return this.nonNullableFb.group({
      effectiveDate: this.nonNullableFb.control<Date>(new Date(Date.now()), [Validators.required, GstRegistrationDateValidator(new Date(company.registrationDate))]),
      companyId: this.nonNullableFb.control<string>(null, [Validators.required]),
      value: this.nonNullableFb.control<GstRegistrationOptionsEnum>(GstRegistrationOptionsEnum.RegisterGst, [Validators.required]),
      jobId: this.fb.control(null),
    })
  }

  requestGstReturnForm() {
    return this.nonNullableFb.group({
      isUsingTenantAccountingService: this.nonNullableFb.control<boolean>(false, [Validators.required]),
      needToPrepareDocument: this.nonNullableFb.control<boolean>(false, [Validators.required]),
      year: this.nonNullableFb.control<number>(null, [Validators.required]),
      quarter: this.nonNullableFb.control<QuarterEnum>(null, [Validators.required]),
      file: this.fb.control<File | null>(null, [Validators.required]),
    })
  }

  gstReturnForm() {
    const {controls} = this.requestGstReturnForm()

    return this.nonNullableFb.group({
      effectiveDate: this.nonNullableFb.control<Date>(new Date(Date.now()), [Validators.required]),
      companyId: this.nonNullableFb.control<string>('', [Validators.required]),
      jobId: this.nonNullableFb.control<string>('', [Validators.required]),
      gstReturnDetail: this.fb.group({
        ...controls,
        requestedFile: this.fb.control<File | null>(null)
      })
    })
  }
}
